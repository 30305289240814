import { Box, Button } from "@mantine/core";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import CustomFilterCommonTable from "../../components/CustomFilterCommonTable";
import Wrapper from "../../components/Wrapper";
import { defaultUseQueryOption } from "../../helper";
import { ListSummaryReportApi } from "../../services";

const columns = [
  {
    dataField: "date",
    text: "Date",
    footerAlign: "left",
    classes: "min-w-[150px] w-[150px]",
    footer: "",
  },
  {
    dataField: "sender",
    text: "Sender",
    footerAlign: "left",
    footer: "Total",
  },
  {
    dataField: "submitted",
    text: "Submitted",
    footerAlign: "left",
    footer: (columnData: any) =>
      columnData.reduce((acc: any, item: any) => acc + item, 0),
  },
  {
    dataField: "delivered",
    text: "Delivered",
    footerAlign: "left",
    footer: (columnData: any) =>
      columnData.reduce((acc: any, item: any) => acc + item, 0),
  },
  {
    dataField: "undelivered",
    text: "Undelivered",
    footerAlign: "left",
    footer: (columnData: any) =>
      columnData.reduce((acc: any, item: any) => acc + item, 0),
  },
  {
    dataField: "failed",
    text: "Failed",
    footerAlign: "left",
    footer: (columnData: any) =>
      columnData.reduce((acc: any, item: any) => acc + item, 0),
  },

  {
    dataField: "rejected",
    text: "Rejected",
    footerAlign: "left",
    footer: (columnData: any) =>
      columnData.reduce((acc: any, item: any) => acc + item, 0),
  },
  {
    dataField: "other",
    text: "Other",
    footerAlign: "left",
    footer: (columnData: any) =>
      columnData.reduce((acc: any, item: any) => acc + item, 0),
  },
];

const AccountOption = [
  {
    key: "#1",
    text: "Today",
    value: "today",
  },
  {
    key: "#5",
    text: "Yesterday",
    value: "yesterday",
  },
  {
    key: "#2",
    text: "Last 7 Days",
    value: "last7",
  },
  {
    key: "#3",
    text: "Last 30 Days",
    value: "last30",
  },
  {
    key: "#4",
    text: "Custom",
    value: "range",
  },
];

const ReportBySender = () => {
  const [filterF, setFilterF] = useState({
    day: "today",
    sender: "",
    from: "",
    to: "",
    status: "completed",
  });

  const { data, refetch } = useQuery<any, Error>(
    "summaryreport",
    () => ListSummaryReportApi(filterF?.day, filterF?.from, filterF?.to),
    defaultUseQueryOption()
  );

  const reportSearch = async () => {
    console.log("filter ::", filterF);
    await refetch();
  };

  const onChangeHandler = (e: any, { value, name }: any = {}) => {
    const e_name = name ? name : e.currentTarget.name;
    const e_value = value ? value : e.currentTarget.value;

    setFilterF((prevState) => ({
      ...prevState,
      [e_name]: e_value ? e_value : "",
    }));
  };

  const [sumReport, setSumReport] = useState<any>([]);

  useEffect(() => {
    if (data?.data) {
      const summedData = _.chain(data?.data)
        .groupBy((item) => {
          const date = item?.createdat.split("T")[0];
          const sender = item?.campaign.sender;
          return `${date}_${sender}`;
        })
        .map((group, sender) => {
          console.log("group", group);
          console.log("gp", sender);

          return {
            sender: sender.split("_")[1],
            date: sender.split("_")[0],
            submitted: _.sumBy(group, "submitted"),
            delivered: _.sumBy(group, "delivered"),
            undelivered: _.sumBy(group, "undelivered"),
            failed: _.sumBy(group, "failed"),
            rejected: _.sumBy(group, "rejected"),
            other: _.sumBy(group, "other"),
          };
        })
        .value();

      console.log(summedData);

      setSumReport(summedData);
    }
  }, [data]);

  return (
    <Wrapper header="Sender Id's Wise" title="Dashboard, Reports">
      <Box p={20} className="rounded-lg bg-white shadow-2xl">
        <div className="mb-10 flex items-end space-x-3">
          <div className="flex flex-col">
            <label>By Date</label>
            <select
              className="rounded-md border-2 border-gray-300  px-4 py-2 hover:border-black"
              onChange={onChangeHandler}
              value={filterF.day}
              name="day"
            >
              {AccountOption.map((a, i) => {
                return (
                  <option key={i} value={a?.value}>
                    {a?.text}
                  </option>
                );
              })}
            </select>
          </div>
          {filterF.day === "range" ? (
            <>
              <div className="flex flex-col">
                <label>From</label>
                <input
                  onChange={onChangeHandler}
                  name="from"
                  type="date"
                  className="rounded-md border-2 border-gray-300  px-4 py-2 hover:border-black"
                />
              </div>
              <div className="flex flex-col">
                <label>To</label>
                <input
                  onChange={onChangeHandler}
                  type="date"
                  name="to"
                  className="rounded-md border-2 border-gray-300  px-4 py-2 hover:border-black"
                />
              </div>
            </>
          ) : null}
          <Button
            size="md"
            variant="filled"
            radius={"md"}
            onClick={reportSearch}
            className="bg-gradient-to-br from-[#4d88c4] to-[#589bd6] px-3 py-3 hover:bg-gradient-to-r hover:from-[#589bd6] hover:to-[#4d88c4]"
          >
            Search
          </Button>
        </div>
        <CustomFilterCommonTable
          data={sumReport}
          columns={columns}
          keyField={"title"}
        />
      </Box>
    </Wrapper>
  );
};

export default ReportBySender;
