import { RxDashboard } from "react-icons/rx";
import {
  TbDeviceDesktopAnalytics,
  TbDeviceMobileMessage,
  TbListDetails,
  TbReportAnalytics,
  TbSettings,
  TbUsers,
} from "react-icons/tb";
import SideMenuItem, { IMenus } from "./SideMenuItem";

import { useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";

const SideMenu = ({ open, setOpen }: { open: boolean; setOpen: Function }) => {
  //@ts-ignore
  const { user } = useAuth();

  const [menus, setMenus] = useState<IMenus[]>([]);

  useEffect(() => {
    if (user?.id === "3d5cf256-781f-4fd1-8cfc-545c87ccea8b") {
      setMenus((prev) => [
        {
          name: "Dashboard",
          link: "/dashboard",
          icon: <RxDashboard />,
          role: ["client", "admin", "reseller"],
        },
        {
          name: "DLT Managment",
          icon: <TbListDetails />,
          role: ["client", "admin", "reseller"],

          submenus: [
            {
              name: "Entities",
              link: "/entities",
            },
            {
              name: "Templates",
              link: "/templates",
            },
          ],
        },
        {
          name: "SMS Campaign",
          icon: <TbDeviceMobileMessage />,
          role: ["client", "admin", "reseller"],

          submenus: [
            {
              name: "Quick Campaign",
              link: "/quick-campaign",
            },
            {
              name: "Upload Campaign",
              link: "/upload-campaign",
            },
            {
              name: "Dynamic Campaign",
              link: "/dynamic-campaign",
            },
            // {
            //   name: "Scheduled Campaign",
            //   link: "/scheduled-campaign",
            // },
          ],
        },
        {
          name: "Report",
          link: "/reports",
          icon: <TbDeviceDesktopAnalytics />,
          role: ["client", "admin", "reseller"],
        },
        {
          name: "Users & Credits",
          icon: <TbUsers />,
          role: ["admin", "reseller"],

          submenus: [
            {
              name: "User Managment",
              link: "/users",
            },
            {
              name: "Credit Managment",
              link: "/credits",
            },
            {
              name: "Credit History",
              link: "/credit-history",
            },
          ],
        },
        {
          name: "Settings",
          link: "/settings",
          icon: <TbSettings />,
          role: ["client", "admin", "reseller"],
        },
      ]);
    } else {
      setMenus((prev) => [
        {
          name: "Dashboard",
          link: "/dashboard",
          icon: <RxDashboard />,
          role: ["client", "admin", "reseller"],
        },
        {
          name: "DLT Managment",
          icon: <TbListDetails />,
          role: ["client", "admin", "reseller"],

          submenus: [
            {
              name: "Entities",
              link: "/entities",
            },
            {
              name: "Templates",
              link: "/templates",
            },
          ],
        },
        {
          name: "SMS Campaign",
          icon: <TbDeviceMobileMessage />,
          role: ["client", "admin", "reseller"],

          submenus: [
            {
              name: "Quick Campaign",
              link: "/quick-campaign",
            },
            {
              name: "Upload Campaign",
              link: "/upload-campaign",
            },
            {
              name: "Dynamic Campaign",
              link: "/dynamic-campaign",
            },
            // {
            //   name: "Scheduled Campaign",
            //   link: "/scheduled-campaign",
            // },
          ],
        },

        {
          name: "Reports",
          icon: <TbReportAnalytics />,
          role: ["admin", "reseller", "client"],

          submenus: [
            {
              name: "Report Summary",
              link: "/report-summary",
            },
            {
              name: "Sender ID Wise",
              link: "/sender-wise",
            },
            {
              name: "Detailed Report",
              link: "/detailed-report",
            },
            {
              name: "Scheduled Report",
              link: "/scheduled-report",
            },
          ],
        },

        {
          name: "Users & Credits",
          icon: <TbUsers />,
          role: ["admin", "reseller"],

          submenus: [
            {
              name: "User Managment",
              link: "/users",
            },
            {
              name: "Credit Managment",
              link: "/credits",
            },
            {
              name: "Credit History",
              link: "/credit-history",
            },
          ],
        },
        {
          name: "Settings",
          link: "/settings",
          icon: <TbSettings />,
          role: ["client", "admin", "reseller"],
        },
      ]);
    }
  }, []);

  return (
    <div
      id="app_sidebar"
      className={`fixed bottom-0 top-0 ${
        open ? "-left-64 md:left-0" : "left-0 md:-left-64"
      } z-50 flex-shrink-0 flex-col space-y-4  bg-white  transition-all md:flex`}
    >
      {/* <div className="flex h-16 items-center justify-center">
        {open ? (
          <img
            src="https://sinthan.com/assets/images/logo.webp"
            alt="logo"
            style={{ width: 200 }}
            className="text-xl"
          />
        ) : (
          <div className="flex w-full items-center justify-between px-3 md:hidden">
            <img
              src="https://sinthan.com/assets/images/logo.webp"
              alt="logo"
              style={{ width: 100 }}
            />
            <button onClick={() => setOpen(!open)}>
              <FaAlignLeft size={24} color="#010101" />
            </button>
          </div>
        )}
      </div> */}
      <div
        id="sidbar_menu"
        className="flex flex-1 select-none overflow-y-auto px-4 pt-4 "
      >
        <ul className="w-full">
          {menus.map((a, i) => {
            return (
              <SideMenuItem
                key={`menu-${i}`}
                role={a.role}
                name={a.name}
                link={a.link}
                icon={a.icon}
                submenus={a.submenus}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default SideMenu;
