import {
  Box,
  Button,
  Radio,
  Select,
  TextInput,
  Textarea
} from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import _ from "lodash";
import { useRef } from "react";
import { FaSave } from "react-icons/fa";
import { useMutation, useQuery } from "react-query";
import Wrapper from "../../components/Wrapper";
import { defaultUseQueryOption } from "../../helper";
import { ITemplateInput, templateSchema } from "../../schema";
import { CreateTemplateApi, ListEntityApi } from "../../services";

const AddTemplate = () => {
  const templateRef = useRef<HTMLTextAreaElement>(null);
  const { data: entities } = useQuery(
    "entities",
    ListEntityApi,
    defaultUseQueryOption()
  );

  const { mutate, isLoading } = useMutation(CreateTemplateApi, {
    onSuccess: () => {
      form.reset();
      showNotification({
        autoClose: 5000,
        title: "Content Template",
        message: "Content Template Added Successfully",
        color: "green"
      })
    },
    onError: () => {
      showNotification({
        autoClose: 5000,
        title: "Content Template",
        message: "Something went wrong, please try again",
        color: "red"
      })
    }
  });

  const form = useForm<ITemplateInput>({
    validate: zodResolver(templateSchema),
    initialValues: {
      contenttype: "service",
      servicetype: "service_explicit",
      description: "",
      unicode: "plaintext",
      title: "",
      template: "",
      operatortempid: "",
      entityid: NaN,
    },
  });

  const onAddTemplate = (values: ITemplateInput) => {
    const data = {
      ..._.omit(values, ["contenttype", "servicetype"]),
      type:
        values.contenttype === "service"
          ? values.servicetype
          : values.contenttype,
    };

    mutate(data);
  };

  const insertVariable = () => {
    const selectionStart = templateRef.current?.selectionStart;
    const selectionEnd = templateRef.current?.selectionEnd;

    const value = form.values.template;

    let newValue =
      value.substring(0, selectionStart) +
      "{#var#}" +
      value.substring(selectionEnd || 0, value.length);

    form.setFieldValue("template", newValue);
  };

  return (
    <Wrapper
      header="Add Content Template"
      title="DLT Managment,Templates, Add Template"
    >
      <Box p={20} className="rounded-lg bg-white shadow-2xl">
        <Box
          color="red"
          component="form"
          onSubmit={form.onSubmit(onAddTemplate)}
          className="rounded-lg bg-[#e8eff7] "
        >
          <div className=" flex flex-col space-y-5 p-5">
            <div className="grid grid-cols-12">
              <div className="col-span-12  md:col-span-3 ">
                Content Template Type
              </div>
              <div className="col-span-12 grid grid-cols-12   md:col-span-9">
                <div className="col-span-12 md:col-span-9">
                  <Radio.Group
                    name="contenttype"
                    {...form.getInputProps("contenttype")}
                  >
                    <Radio value="transactional" label="Transactional" />
                    <Radio value="promotional" label="Promotional" />
                    <Radio value="service" label="Service" />
                  </Radio.Group>
                </div>
                <div className="col-span-12  md:col-span-3"></div>
              </div>
            </div>
            {form.values.contenttype === "service" && (
              <div className="grid grid-cols-12">
                <div className="col-span-12  md:col-span-3 ">Service Type</div>
                <div className="col-span-12 grid grid-cols-12   md:col-span-9">
                  <div className="col-span-12 md:col-span-9">
                    <Radio.Group
                      name="servicetype"
                      {...form.getInputProps("servicetype")}
                    >
                      <Radio
                        value="service_implicit"
                        label="Service Implicit"
                      />
                      <Radio
                        value="service_explicit"
                        label="Service Explicit"
                      />
                    </Radio.Group>
                  </div>
                  <div className="col-span-12  md:col-span-3"></div>
                </div>
              </div>
            )}

            <div className="grid grid-cols-12">
              <div className="col-span-12  md:col-span-3 ">Sender Id</div>
              <div className="col-span-12 grid grid-cols-12   md:col-span-9">
                <div className="col-span-12 md:col-span-9">
                  <Select
                    placeholder="Pick one"
                    data={
                      entities?.data?.map((a: any) => {
                        return { value: a?.id, label: a?.sender };
                      }) || []
                    }
                    {...form.getInputProps("entityid")}
                  />
                </div>
                <div className="col-span-12  md:col-span-3"></div>
              </div>
            </div>

            <div className="grid grid-cols-12">
              <div className="col-span-12  md:col-span-3 ">Template Name</div>
              <div className="col-span-12 grid grid-cols-12   md:col-span-9">
                <div className="col-span-12 md:col-span-9">
                  <TextInput
                    placeholder="Template Name"
                    size="md"
                    {...form.getInputProps("title")}
                  />
                </div>
                <div className="col-span-12  md:col-span-3"></div>
              </div>
            </div>

            <div className="grid grid-cols-12">
              <div className="col-span-12  md:col-span-3 ">
                {" "}
                Template Description
              </div>
              <div className="col-span-12 grid grid-cols-12   md:col-span-9">
                <div className="col-span-12 md:col-span-9">
                  <TextInput
                    placeholder="Template Description"
                    size="md"
                    {...form.getInputProps("description")}
                  />
                </div>
                <div className="col-span-12  md:col-span-3"></div>
              </div>
            </div>

            <div className="grid grid-cols-12">
              <div className="col-span-12  md:col-span-3 ">
                Operator Template Id
              </div>
              <div className="col-span-12 grid grid-cols-12   md:col-span-9">
                <div className="col-span-12 md:col-span-9">
                  <TextInput
                    placeholder="eg : 0000000000000000000"
                    size="md"
                    {...form.getInputProps("operatortempid")}
                  />
                </div>
                <div className="col-span-12  md:col-span-3"></div>
              </div>
            </div>

            <div className="grid grid-cols-12">
              <div className="col-span-12  md:col-span-3 ">Unicode</div>
              <div className="col-span-12 grid grid-cols-12   md:col-span-9">
                <div className="col-span-12 md:col-span-9">
                  <Radio.Group
                    name="unicode"
                    {...form.getInputProps("unicode")}
                  >
                    <Radio value="plaintext" label="Plain Text" />
                    <Radio value="unicode" label="Unicode" />
                  </Radio.Group>
                </div>
                <div className="col-span-12  md:col-span-3"></div>
              </div>
            </div>

            <div className="grid grid-cols-12">
              <div className="col-span-12  md:col-span-3 ">Message Text</div>
              <div className="col-span-12 grid grid-cols-12   md:col-span-9">
                <div className="col-span-12 md:col-span-9">
                  <Textarea
                    placeholder="Message Text"
                    size="md"
                    minRows={4}
                    ref={templateRef}
                    {...form.getInputProps("template")}
                  />
                </div>
                <div className="col-span-12  px-2 md:col-span-3">
                  <Button
                    radius={"md"}
                    variant="filled"
                    className="bg-gradient-to-br from-[#4d88c4] to-[#589bd6] px-3 py-3 hover:bg-gradient-to-r hover:from-[#589bd6] hover:to-[#4d88c4]"
                    onClick={insertVariable}
                    size="sm"
                  >
                    <span className="ml-2">Insert Variable</span>
                  </Button>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-12">
              <div className="col-span-12  md:col-span-3 "></div>
              <div className="col-span-12 grid grid-cols-12   md:col-span-9">
                <div className="col-span-12 md:col-span-9">
                  <div className="basis-full space-x-3 md:basis-1/2 ">
                    <Button
                      size="md"
                      variant="filled"
                      className="bg-gradient-to-br from-[#4d88c4] to-[#589bd6] px-7 py-3 hover:bg-gradient-to-r hover:from-[#589bd6] hover:to-[#4d88c4]"
                      type="submit"
                      loading={isLoading}
                    >
                      <FaSave /> <span className="ml-2">Submit</span>
                    </Button>
                    <Button size="md" variant="outline" color={"#4d88c4"}>
                      Cancel
                    </Button>
                  </div>
                  <div className="col-span-12  md:col-span-3"></div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default AddTemplate;

//Message submitted to platform. Total Number Uploaded :- 5 .Duplicate/Incorrect Number Count :- 4 .Number submitted to platform:- 1
