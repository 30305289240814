import {
  Box,
  Button,
  Checkbox,
  Select,
  Space,
  Switch,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { useEffect } from "react";
import { FaSave } from "react-icons/fa";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { IUserInput, userSchema } from "../schema";
import { EditUserbyIdApi } from "../services";

const UserInfoDetails = ({ user }: { user: IUserInput }) => {
  const params = useParams();

  const { mutate, isLoading } = useMutation(EditUserbyIdApi, {
    onSuccess: (data) => {
      form.reset();
      showNotification({
        autoClose: 5000,
        title: "Create update",
        message: "User Updated successfully done",
        color: "green",
      });
    },
    onError: () => {
      showNotification({
        autoClose: 5000,
        title: "Create update",
        message: "Something went wrong, please try again",
        color: "red",
      });
    },
  });

  const form = useForm<IUserInput>({
    validate: zodResolver(userSchema),
    initialValues: {
      username: user?.username,
      fullname: user?.fullname,
      role: user?.role,
      password: user?.misc?.password || "",
      phone: user?.phone,
      email: user?.email,
      misc: user?.misc,
      reseller: {
        domain: user?.reseller?.domain,
      },
      canaddvirtualcredit: user?.canaddvirtualcredit,
      viewcredithistory: user?.viewcredithistory,
      status: user?.status,
    },
  });

  useEffect(() => {
    form.setValues({
      username: user?.username,
      fullname: user?.fullname,
      role: user?.role,
      password: user?.misc?.password || "",
      phone: user?.phone,
      email: user?.email,
      misc: user?.misc,
      reseller: {
        domain: user?.reseller?.domain,
      },
      canaddvirtualcredit: user?.canaddvirtualcredit,
      viewcredithistory: user?.viewcredithistory,
      status: user?.status,
    });
  }, [user]);

  const onAddUser = (values: IUserInput) => {
    const data = {
      ...values,
      id: params?.id,
    };

    console.log(data);

    mutate(data);
  };

  return (
    <Box component="form" onSubmit={form.onSubmit(onAddUser)}>
      <div className=" flex flex-col space-y-5 p-5">
        <div className="flex flex-row flex-wrap items-center">
          <div className="basis-full md:basis-1/3">Username</div>
          <div className="basis-full md:basis-1/2">{form?.values.username}</div>
        </div>

        <div className="flex flex-row flex-wrap items-center">
          <div className="basis-full md:basis-1/3">Full Name</div>
          <div className="basis-full md:basis-1/2">
            <TextInput
              placeholder="Full Name"
              size="md"
              {...form.getInputProps("fullname")}
            />
          </div>
        </div>

        <div className="flex flex-row flex-wrap items-center">
          <div className="basis-full md:basis-1/3">Mobile Number</div>
          <div className="basis-full md:basis-1/2">
            <TextInput
              placeholder="Mobile Number"
              size="md"
              {...form.getInputProps("phone")}
              value={form.values.phone}
            />
          </div>
        </div>

        <div className="flex flex-row flex-wrap items-center">
          <div className="basis-full md:basis-1/3">Email</div>
          <div className="basis-full md:basis-1/2">
            <TextInput
              placeholder="Email"
              size="md"
              {...form.getInputProps("email")}
              value={form.values.email}
            />
          </div>
        </div>

        <div className="flex flex-row flex-wrap items-center">
          <div className="basis-full md:basis-1/3">Password</div>
          <div className="basis-full md:basis-1/2">
            {form?.values?.password}
          </div>
        </div>

        <div className="flex flex-row flex-wrap items-center">
          <div className="basis-full md:basis-1/3">Role</div>
          <div className="basis-full capitalize md:basis-1/2">
            {form.values.role}
          </div>
        </div>

        {form.values.role === "reseller" && (
          <>
            <Text size="md" className="font-semibold">
              Reseller Details
            </Text>
            <div className="flex flex-row flex-wrap items-center">
              <div className="basis-full md:basis-1/3">Domain</div>
              <div className="basis-full md:basis-1/2">
                <TextInput
                  placeholder="Domain Url"
                  size="md"
                  {...form.getInputProps("reseller.domain")}
                />
              </div>
            </div>
            <div className="flex flex-row flex-wrap items-center">
              <div className="basis-full md:basis-1/3">
                Can add virtual SMS to there client(s)
              </div>
              <div className="basis-full md:basis-1/2">
                <Switch
                  size="md"
                  onLabel="Yes"
                  offLabel="No"
                  {...form.getInputProps("canaddvirtualcredit")}
                  defaultChecked={form.values.canaddvirtualcredit}
                />
              </div>
            </div>
          </>
        )}

        <div className="flex flex-row flex-wrap items-center">
          <div className="basis-full md:basis-1/3">View Credit History</div>
          <div className="basis-full md:basis-1/2">
            <Switch
              size="md"
              onLabel="Yes"
              offLabel="No"
              {...form.getInputProps("viewcredithistory")}
              defaultChecked={form.values.viewcredithistory}
            />
          </div>
        </div>
        {/* <div className="flex flex-row flex-wrap items-center">
              <div className="basis-full md:basis-1/3">View Invoice</div>
              <div className="basis-full md:basis-1/2">
                <Switch
                  size="md"
                  onLabel="Yes"
                  offLabel="No"
                  {...form.getInputProps("misc.isInvoiceView")}
                />
              </div>
            </div> */}

        <div className="flex flex-row flex-wrap items-center">
          <div className="basis-full md:basis-1/3">User Type</div>
          <div className="basis-full md:basis-1/2">
            <Checkbox.Group
              defaultValue={form.values.misc.usertype}
              {...form.getInputProps("misc.usertype")}
            >
              <Checkbox value="web" label="Web" />
              <Checkbox value="api" label="Api" />
            </Checkbox.Group>
          </div>
        </div>

        <div className="flex flex-row flex-wrap items-center">
          <div className="basis-full md:basis-1/3">Status</div>
          <div className="basis-full md:basis-1/2">
            <Select
              placeholder="Select Status"
              size="md"
              defaultValue={form.values.status}
              {...form.getInputProps("status")}
              data={[
                { value: "active", label: "Active" },
                { value: "suspended", label: "Suspended" },
                { value: "deleted", label: "Deleted" },
              ]}
            />
          </div>
        </div>

        <div>
          <Space h={"md"} />
          <div className="flex flex-row flex-wrap items-center">
            <div className="basis-full md:basis-1/3"></div>
            <div className="basis-full space-x-3 md:basis-1/2 ">
              <Button
                size="md"
                variant="filled"
                className="bg-gradient-to-br from-[#4d88c4] to-[#589bd6] px-7 py-3 hover:bg-gradient-to-r hover:from-[#589bd6] hover:to-[#4d88c4]"
                type="submit"
                //   loading={isLoading}
              >
                <FaSave /> <span className="ml-2">Update</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default UserInfoDetails;
