import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import Login from "../pages/Login";
import Dashboard from "../pages/dashboard";

import Entities from "../pages/dltmanagment/Entities";
import Templates from "../pages/dltmanagment/Templates";

import Settings from "../pages/settings";

import DynamicCampaign from "../pages/smscampaign/DynamicCampaign";
import QuickCampaign from "../pages/smscampaign/QuickCampaign";
import ScheduledCampaign from "../pages/smscampaign/ScheduledCampaign";
import UploadCampaign from "../pages/smscampaign/UploadCampaign";

import CreditHistory from "../pages/user/CreditHistory";
import Users from "../pages/user/Users";

import AddEntity from "../pages/dltmanagment/AddEntity";
import AddTemplate from "../pages/dltmanagment/AddTemplate";
import Reports from "../pages/report";
import ReportBySender from "../pages/report/bysender";
import ReportByTrxSummary from "../pages/report/bytrx";
import DetailedReport from "../pages/report/detailed";
import ScheduledReport from "../pages/report/scheduled";
import ReportSummary from "../pages/report/summary";
import AddUser from "../pages/user/AddUser";
import CreditManagment from "../pages/user/CreditManagment";
import UserDetails from "../pages/user/UserDetail";
import RequireAuth from "./RequireAuth";

const ONLYADMIN = ["admin", "reseller"]; // change to "admin" in Production

const Missing = () => {
  return (
    <h1 className="flex items-center justify-center p-5 text-xl font-semibold text-red-400">
      Missing route
    </h1>
  );
};

const Unauthorized = () => {
  return (
    <h1 className="flex items-center justify-center p-5 text-xl font-semibold text-red-400">
      Un Authorized
    </h1>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      {/* Public routes */}
      <Route path="/" element={<Login />} />
      <Route path="unauthorized" element={<Unauthorized />} />

      {/* Protected routes */}
      <Route>
        <Route
          element={
            <RequireAuth allowedRoles={["client", "admin", "reseller"]} />
          }
        >
          <Route path="/dashboard" element={<Dashboard />} />
        </Route>

        <Route
          element={
            <RequireAuth allowedRoles={["client", "admin", "reseller"]} />
          }
        >
          <Route path="/entities" element={<Entities />} />
        </Route>

        <Route
          element={
            <RequireAuth allowedRoles={["client", "admin", "reseller"]} />
          }
        >
          <Route path="/add-entity" element={<AddEntity />} />
        </Route>

        <Route
          element={
            <RequireAuth allowedRoles={["client", "admin", "reseller"]} />
          }
        >
          <Route path="/add-template" element={<AddTemplate />} />
        </Route>

        <Route
          element={
            <RequireAuth allowedRoles={["client", "admin", "reseller"]} />
          }
        >
          <Route path="/templates" element={<Templates />} />
        </Route>

        <Route
          element={
            <RequireAuth allowedRoles={["client", "admin", "reseller"]} />
          }
        >
          <Route path="/quick-campaign" element={<QuickCampaign />} />
        </Route>
        <Route
          element={
            <RequireAuth allowedRoles={["client", "admin", "reseller"]} />
          }
        >
          <Route path="/dynamic-campaign" element={<DynamicCampaign />} />
        </Route>
        <Route
          element={
            <RequireAuth allowedRoles={["client", "admin", "reseller"]} />
          }
        >
          <Route path="/upload-campaign" element={<UploadCampaign />} />
        </Route>
        <Route
          element={
            <RequireAuth allowedRoles={["client", "admin", "reseller"]} />
          }
        >
          <Route path="/scheduled-campaign" element={<ScheduledCampaign />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={ONLYADMIN} />}>
          <Route path="/users" element={<Users />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={ONLYADMIN} />}>
          <Route path="/user/:id" element={<UserDetails />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ONLYADMIN} />}>
          <Route path="/edit-user/:id" element={<UserDetails />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={ONLYADMIN} />}>
          <Route path="/add-user" element={<AddUser />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={ONLYADMIN} />}>
          <Route path="/credits" element={<CreditManagment />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={ONLYADMIN} />}>
          <Route path="/credit-history" element={<CreditHistory />} />
        </Route>

        <Route
          element={
            <RequireAuth allowedRoles={["client", "admin", "reseller"]} />
          }
        >
          <Route path="/reports" element={<Reports />} />
        </Route>
        <Route
          element={
            <RequireAuth allowedRoles={["client", "admin", "reseller"]} />
          }
        >
          <Route path="/report-summary" element={<ReportSummary />} />
        </Route>
        <Route
          element={
            <RequireAuth allowedRoles={["client", "admin", "reseller"]} />
          }
        >
          <Route path="/detailed-report" element={<DetailedReport />} />
        </Route>
        <Route
          element={
            <RequireAuth allowedRoles={["client", "admin", "reseller"]} />
          }
        >
          <Route path="/scheduled-report" element={<ScheduledReport />} />
        </Route>
        <Route
          element={
            <RequireAuth allowedRoles={["client", "admin", "reseller"]} />
          }
        >
          <Route path="/sender-wise" element={<ReportBySender />} />
        </Route>
        <Route
          element={
            <RequireAuth allowedRoles={["client", "admin", "reseller"]} />
          }
        >
          <Route path="/trxreport/:id" element={<ReportByTrxSummary />} />
        </Route>
        <Route
          element={
            <RequireAuth allowedRoles={["client", "admin", "reseller"]} />
          }
        >
          <Route path="/settings" element={<Settings />} />
        </Route>
      </Route>

      {/* catch all */}
      <Route path="*" element={<Missing />} />
    </Route>
  )
);

const AppRoutes = () => {
  return <RouterProvider router={router} fallbackElement={<Missing />} />;
};

export default AppRoutes;
