import { NotificationsProvider } from "@mantine/notifications";
import React from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthProvider } from "./context/AuthProvider";
import App from "./pages";
import reportWebVitals from "./reportWebVitals";
import "./styles/index.css";
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <NotificationsProvider position="top-center">
        <AuthProvider>
          <App />
        </AuthProvider>
      </NotificationsProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
