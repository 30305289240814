import { Box, Button } from "@mantine/core";
import _ from "lodash";
import { useState } from "react";
import { RiDownload2Fill } from "react-icons/ri";
import CustomFilterCommonTable from "../../components/CustomFilterCommonTable";
import Wrapper from "../../components/Wrapper";
const tabl = [
  {
    id: 1,
    senderid: "RAJAWS",
    date: "19/04/2024",
    submit: 1,
    delivery: 1,
    file: "mislog-RAJASTHANAWAS-2024-04-19.csv",
    failed: 0,
  },
  {
    id: 2,
    senderid: "RAJAWS",
    date: "20/04/2024",
    submit: 400000,
    delivery: 326845,
    file: "mislog-RAJASTHANAWAS-2024-04-20.csv",
    failed: 73155,
  },
  {
    id: 3,
    senderid: "RAJAWS",
    date: "21/04/2024",
    submit: 400000,
    delivery: 278423,
    file: "mislog-RAJASTHANAWAS-2024-04-21.csv",
    failed: 121577,
  },

  {
    id: 4,
    senderid: "RAJAWS",
    date: "26/04/2024",
    submit: 1,
    delivery: 1,
    file: "mislog-RAJASTHANAWAS-2024-04-26.csv",
    failed: 0,
  },
  {
    id: 5,
    senderid: "RAJAWS",
    date: "27/04/2024",
    submit: 125000,
    delivery: 90158,
    file: "mislog-RAJASTHANAWAS-2024-04-27.csv",
    failed: 34842,
  },
];

const Actions = (_cell: any, row: any) => {
  return (
    <a
      target="_blank"
      href={`/files/${row.file}`}
      download={`${row.file}`}
      rel="noreferrer"
    >
      <RiDownload2Fill color="red" />
    </a>
  );
};

const columns = [
  {
    dataField: "date",
    text: "Date",
    headerAlign: "left",
  },
  {
    dataField: "senderid",
    text: "Sender Id",
    headerAlign: "left",
  },
  {
    dataField: "submit",
    text: "Submit",
    headerAlign: "left",
  },
  {
    dataField: "delivery",
    text: "Delivered",
    headerAlign: "left",
  },
  {
    dataField: "failed",
    text: "Failed",
    headerAlign: "left",
  },
  {
    dataField: "#",
    text: "Download",
    headerAlign: "left",
    formatter: Actions,
  },
];

const Reports = () => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [sender, setSender] = useState("");
  const [message, setMessage] = useState("Search by senderid,from,to");

  const [report, setReport] = useState<
    {
      id: number;
      senderid: string;
      date: string;
      submit: number;
      delivery: number;
      file: string;
      failed: number;
    }[]
  >([]);

  const reportSearch = () => {
    console.log({ sender, fromDate, toDate });

    let filtered: {
      id: number;
      senderid: string;
      date: string;
      submit: number;
      delivery: number;
      file: string;
      failed: number;
    }[] = [];

    if (
      (sender === "" || sender === "--select--") &&
      fromDate === "" &&
      toDate === ""
    ) {
      setReport((prev) => []);
      setMessage((prev) => "Select senderid and from-to for search results.");
    } else if (sender === "all" && fromDate === "" && toDate === "") {
      setReport((prev) => _.sortBy(tabl, "date"));
      setMessage((prev) => "");
    } else if (sender === "all" && fromDate !== "" && toDate !== "") {
      filtered = tabl.filter((item) => {
        const date = item.date.split("/").reverse().join("-"); // convert date format to ISO format (YYYY-MM-DD)
        return (
          new Date(date) >= new Date(fromDate) &&
          new Date(date) <= new Date(toDate)
        );
      });
      setReport((prev) => _.sortBy(filtered, "date"));

      setMessage((prev) => (filtered.length > 0 ? "" : "Not result found"));
    } else if (sender !== "all" && fromDate === "" && toDate === "") {
      filtered = tabl.filter((item) => {
        return item.senderid === sender;
      });
      setReport((prev) => _.sortBy(filtered, "date"));
      setMessage((prev) => (filtered.length > 0 ? "" : "Not result found"));
    } else {
      filtered = tabl.filter((item) => {
        const date = item.date.split("/").reverse().join("-"); // convert date format to ISO format (YYYY-MM-DD)
        return (
          item.senderid === sender &&
          new Date(date) >= new Date(fromDate) &&
          new Date(date) <= new Date(toDate)
        );
      });
      setReport((prev) => _.sortBy(filtered, "date"));
      setMessage((prev) => (filtered.length > 0 ? "" : "Not result found"));
    }
  };

  return (
    <Wrapper header="Report Summary" title="Dashboard, Reports">
      <Box p={20} className="rounded-lg bg-white shadow-2xl">
        <div className="mb-10 flex items-end space-x-3">
          <div className="flex flex-col">
            <label>Sender Id</label>
            <select
              className="rounded-md border-2 border-gray-300  px-4 py-2 hover:border-black"
              onChange={(e) => setSender(e.target.value)}
            >
              <option value="--select--">-- select sender id -- </option>
              {Object.keys(_.groupBy(tabl, "senderid")).map((a, i) => {
                return <option value={a}>{a}</option>;
              })}
              <option value="all">All</option>
            </select>
          </div>
          <div className="flex flex-col">
            <label>From</label>
            <input
              onChange={(e) => setFromDate(e.target.value)}
              type="date"
              className="rounded-md border-2 border-gray-300  px-4 py-2 hover:border-black"
            />
          </div>
          <div className="flex flex-col">
            <label>To</label>
            <input
              onChange={(e) => setToDate(e.target.value)}
              type="date"
              className="rounded-md border-2 border-gray-300  px-4 py-2 hover:border-black"
            />
          </div>

          <Button
            size="md"
            variant="filled"
            radius={"md"}
            onClick={reportSearch}
            className="bg-gradient-to-br from-[#4d88c4] to-[#589bd6] px-3 py-3 hover:bg-gradient-to-r hover:from-[#589bd6] hover:to-[#4d88c4]"
          >
            Search
          </Button>
        </div>
        <CustomFilterCommonTable
          data={report}
          columns={columns}
          keyField={"id"}
        />

        <div className="my-10 flex items-center justify-center text-xl text-gray-400">
          {message}
        </div>
      </Box>
    </Wrapper>
  );
};

export default Reports;
