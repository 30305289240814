//@ts-nocheck

import { Collapse } from "@mantine/core";
import { useState } from "react";
import { RxCaretDown } from "react-icons/rx";
import { NavLink } from "react-router-dom";
import useAuth from "../hooks/useAuth";
export interface IMenus {
  name: string;
  link?: string;
  icon?: React.ReactElement;
  submenus?: ISubMenus[];
  role?: string[];
}

interface ISubMenus {
  name: string;
  link: string;
}

const SubMenus = ({ submenus }: { submenus: ISubMenus[] | undefined }) => {
  return (
    <div className={`opacity-1 min-h-0 transition-all`}>
      <ul>
        {submenus?.map((a, i) => {
          return (
            <li key={`submenu-${i}`} className="mb-2">
              <NavLink to={a.link}>
                <div className="text-md flex cursor-pointer items-center justify-between rounded-md py-2  pl-4 pr-3   text-gray-600 hover:bg-gray-200 ">
                  <div className="flex items-center space-x-3  font-semibold ">
                    <span className="flex h-6 w-6 items-center justify-center">
                      <span className="h-1 w-1 rounded-full bg-gray-400" />
                    </span>
                    <span>{a.name}</span>
                  </div>
                </div>
              </NavLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const SideMenuItem = ({ name, link, submenus, icon, role }: IMenus) => {
  const [open, setOpen] = useState(false);
  const { user } = useAuth();

  return user.role?.find((ro) => role?.includes(ro)) ? (
    <>
      {link && (
        <li className="mb-2">
          <NavLink to={link}>
            <div
              onClick={() => {
                setOpen(!open);
              }}
              className="text-md flex cursor-pointer items-center justify-between rounded-md py-2  pl-4 pr-3 text-gray-600   hover:bg-gray-200 hover:text-gray-600"
            >
              <div className="flex items-center space-x-3  font-semibold ">
                <span className="/> flex h-6 w-6 items-center justify-center text-xl">
                  {icon}
                </span>
                <span className="select-none">{name}</span>
              </div>
              {submenus && (
                <span>
                  <RxCaretDown
                    className={`text-xl transition-all ${
                      open ? "-rotate-180" : "-rotate-0"
                    }`}
                  />
                </span>
              )}
            </div>
          </NavLink>
        </li>
      )}

      {!link && (
        <li className="mb-2">
          <div
            onClick={() => {
              setOpen(!open);
            }}
            className="text-md flex cursor-pointer items-center justify-between rounded-md py-2  pl-4 pr-3 text-gray-600   hover:bg-gray-200 hover:text-gray-600"
          >
            <div className="flex items-center space-x-3  font-semibold ">
              <span className="/> flex h-6 w-6 items-center justify-center text-xl">
                {icon}
              </span>
              <span className="select-none">{name}</span>
            </div>
            {submenus && (
              <span>
                <RxCaretDown
                  className={`text-xl transition-all ${
                    open ? "-rotate-180" : "-rotate-0"
                  }`}
                />
              </span>
            )}
          </div>
          <Collapse in={open}>
            <SubMenus submenus={submenus} />
          </Collapse>
        </li>
      )}
    </>
  ) : null;
};

export default SideMenuItem;
