import { RiDeleteBin2Line } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import CommonTable from "../../components/CommonTable";
import Wrapper from "../../components/Wrapper";
import { defaultUseQueryOption } from "../../helper";
import { DeleteTemplateApi, ListTemplateApi } from "../../services";

import { Box, Modal } from "@mantine/core";
import { useState } from "react";

const Templates = () => {
  const { data,refetch } = useQuery(
    "templates",
    ListTemplateApi,
    defaultUseQueryOption()
  );

  const [opened, setOpened] = useState(false);
  const [delId, setDelId] = useState(0);

  const { mutate: deleteE, isLoading } = useMutation(DeleteTemplateApi, {
    onSuccess: (data) => {
      refetch()
      setOpened(!opened)
      setDelId(0)
    },
  });


  const Actions = (_cell: any, row: any) => {
    return (
      <div role="button" onClick={() => {
        setOpened(true)
        setDelId(row?.id)
      }} >
        <RiDeleteBin2Line color="red" />
      </div>
    );
  };

  const columns = [
    {
      dataField: "title",
      text: "Title",
      headerAlign: "left",
    },

    {
      dataField: "template",
      text: "Template",
      headerAlign: "left",
    },
    {
      dataField: "description",
      text: "Description",
      headerAlign: "left",
    },
    {
      dataField: "operatortempid",
      text: "Operator Template Id",
      headerAlign: "left",
    },
    {
      dataField: "type",
      text: "Type",
      headerAlign: "left",
    },
    {
      dataField: "#",
      text: "#",
      headerAlign: "left",
      formatter: Actions,
    },
  ];


  return (
    <Wrapper header="Templates" title="DLT Managment, Templates">
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title="Delete Entity"
        withCloseButton={false}
      >
        <div>
          <p>Are you sure do you want to delete ?</p>
          <div className="flex gap-x-2 justify-end mt-4" >
            <button onClick={() => deleteE({ id: delId })} className="border-[1px] rounded-md  px-3 bg-red-600 text-white py-1 border-red-600" >{isLoading ? "loading" : "Delete"}</button>
            <button onClick={() => setOpened(!opened)} className="border-[1px] rounded-md px-3 py-1 border-gray-500" >Cancel</button>
          </div>
        </div>
      </Modal>
      <Box p={20} className="rounded-lg bg-white shadow-2xl">
        <CommonTable
          columns={columns}
          data={data?.data}
          keyField="operatortempid"
          title="Templates"
          link={{
            link: "/add-template",
            title: "Add Template",
          }}
        />
      </Box>
    </Wrapper>
  );
};

export default Templates;
