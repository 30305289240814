//@ts-nocheck
import { Button } from "@mantine/core";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

const CommonTable = ({ columns, data, keyField, title, link }) => {
  return (
    <ToolkitProvider
      search
      data={data || []}
      columns={columns}
      keyField={keyField}
      title={title}
      exportCSV
    >
      {(props: any) => (
        <div className="rounded-lg  bg-[#e8eff7] shadow">
          <div className="flex flex-col p-5 md:flex-row md:items-center md:justify-between">
            <h3 className="mb-2 text-lg font-semibold">{title}</h3>
            <div className="s_bar space-3 flex flex-col items-center justify-center md:flex-row  md:space-x-3 md:space-y-0">
              <SearchBar
                className=" rounded-full border-2 border-gray-300  px-4 py-2 hover:border-black"
                placeholder="Search..."
                {...props.searchProps}
              />
              <ExportCSVButton {...props.csvProps}>Export</ExportCSVButton>
              {link !== null && (
                <Link to={link?.link}>
                  <Button
                    size="md"
                    variant="filled"
                    className="bg-gradient-to-br from-[#4d88c4] to-[#589bd6] px-3 py-3 hover:bg-gradient-to-r hover:from-[#589bd6] hover:to-[#4d88c4]"
                  >
                    {link?.title}
                  </Button>
                </Link>
              )}
            </div>
          </div>
          <div className="relative  max-h-[calc(100vh-350px)]   overflow-x-auto ">
            <BootstrapTable
              classes="w-full"
              {...props.baseProps}
              pagination={paginationFactory()}
            />
          </div>
        </div>
      )}
    </ToolkitProvider>
  );
};

export default CommonTable;
