import { z } from "zod";

export const entitySchema = z.object({
  sender: z
    .string()
    .regex(/^(\d|\w)+$/, {
      message: "No special characters or space allowed",
    })
    .min(6)
    .max(6)
    .trim(),
  header: z
    .string()
    .regex(/^(\d|\w)+$/, {
      message: "No special characters or space allowed",
    })
    .min(6)
    .trim(),
  entity: z
    .string()
    .regex(/^(\d|\w)+$/, {
      message: "No special characters or space allowed",
    })
    .min(6)
    .trim(),
  type: z.enum(["promotional", "others"]),
});

export type IEntityInput = z.infer<typeof entitySchema>;

export const templateSchema = z.object({
  entityid: z
    .number({ description: "Select Sender Id" })
    .gte(0, { message: "Select Sender Id" }),
  title: z.string().min(1).trim(),
  description: z.string().optional(),
  contenttype: z.enum(["transactional", "promotional", "service"]),
  servicetype: z.enum(["service_implicit", "service_explicit"]).optional(),
  operatortempid: z
    .string()
    .regex(/^(\d|\w)+$/, {
      message: "No special characters or space allowed",
    })
    .min(6)
    .trim(),
  unicode: z.enum(["plaintext", "unicode"]),
  template: z.string().min(1),
});

export type ITemplateInput = z.infer<typeof templateSchema>;

const Address = z.object({
  address1: z.string(),
  address2: z.string().optional(),
  state: z.string(),
  city: z.string(),
  zip: z.string(),
});

const Company = z.object({
  name: z.string().min(3).max(290),
  address: z
    .object({
      ...Address.shape,
    })
    .optional(),
  phone: z
    .string()
    .regex(/^[6-9]\d{9}$/, {
      message: "Enter a valid mobile number eg: xxxxxxxxxx",
    })
    .optional(),
  email: z
    .string()
    .email({ message: "Enter a valid email eg: example@gmail.com" })
    .optional(),
});

export const userSchema = z.object({
  role: z.enum(["reseller", "client"]),
  username: z
    .string()
    .regex(/^(\d|\w)+$/, {
      message: "No special characters or space allowed",
    })
    .min(3)
    .max(72)
    .trim(),
  fullname: z.string().min(3).max(290),
  phone: z
    .string()
    .refine(
      (phone) => {
        console.log("phone:: ", phone);
        if (phone === "") return true;
        return /^[6-9]\d{9}$/.test(phone);
      },
      {
        message: "Enter a valid mobile number eg: xxxxxxxxxx",
      }
    )
    .optional(),

  email: z
    .string()
    .refine(
      (email) => {
        if (email === "") return true;
        return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
      },
      { message: "Enter a valid email eg: example@gmail.com" }
    )
    .optional(),

  password: z
    .string()
    .trim()
    .superRefine((password, ctx) => {
      if (!/[A-Z]/.test(password)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Password must contain at least 1 uppercase letter",
        });
      }
      if (!/[!@#$%^&*]/.test(password)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            "Password must contain at least 1 special character: !@#$%^&*",
        });
      }
      if (/^\d/.test(password)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Password must not start with a number",
        });
      }

      if (/[!@#$%^&*]$/.test(password)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Password must not end with a special character",
        });
      }
      if (password.length < 6) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Password must be at least 6 characters long",
        });
      }
    }),
  address: z
    .object({
      ...Address.shape,
    })
    .optional(),
  company: z
    .object({
      ...Company.shape,
    })
    .optional(),

  misc: z.object({
    usertype: z.array(z.enum(["web", "api"])),
    password: z.string().optional(),
  }),
  reseller: z
    .object({
      domain: z.string().optional(),
      canmakereseller: z.boolean().optional(),
    })
    .optional(),

  viewcredithistory: z.boolean(),
  canaddvirtualcredit: z.boolean(),
  status: z.enum(["active", "suspended", "deleted"]),
});

export type IUserInput = z.infer<typeof userSchema>;

export const creditSchema = z.object({
  clientid: z.string().uuid("Select username"),
  credit: z
    .string()
    .or(z.number())
    .refine(
      (credit) => {
        console.log("Credit::", credit);

        return Number(credit) > 0;
      },
      {
        message: "Enter a valid credit greater than 0",
      }
    ),
  amount: z
    .string()
    .or(z.number())
    .refine((amount) => Number(amount) > 0, {
      message: "Enter a valid amount greater than 0",
    })
    .optional(),
  gst: z
    .string()
    .or(z.number())
    .refine((gst) => Number(gst) > 0, {
      message: "Enter a valid GST in % greater than 0",
    })
    .optional(),
  mode: z.enum(["credit", "debit"]),
  description: z.string().optional(),
});

export type ICreditInput = z.infer<typeof creditSchema>;
