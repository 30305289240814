import { Box } from "@mantine/core";
import { RiEdit2Line, RiEyeLine } from "react-icons/ri";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import CommonTable from "../../components/CommonTable";
import Wrapper from "../../components/Wrapper";
import { defaultUseQueryOption } from "../../helper";
import { UserListApi } from "../../services";

const Actions = (_cell: any, row: any) => {
  return (
    <div className="flex gap-x-3">
      <Link to={`/user/${row?.id}`}>
        <RiEyeLine size={20} color="royalblue" />
      </Link>
      <Link to={`/edit-user/${row?.id}`}>
        <RiEdit2Line size={20} color="royalblue" />
      </Link>
    </div>
  );
};

const columns = [
  {
    dataField: "username",
    text: "Username",
    headerAlign: "left",
  },
  {
    dataField: "fullname",
    text: "Full Name",
    headerAlign: "left",
  },
  {
    dataField: "email",
    text: "Email",
    headerAlign: "left",
  },
  {
    dataField: "phone",
    text: "Phone",
    headerAlign: "left",
  },

  {
    dataField: "sbuser[0]",
    text: "Balance",
    headerAlign: "left",
  },

  {
    dataField: "status",
    text: "Status",
    headerAlign: "left",
  },

  {
    dataField: "role",
    text: "Role",
    headerAlign: "left",
  },

  {
    dataField: "#",
    text: "Action",
    headerAlign: "left",
    formatter: Actions,
  },
];

const Users = () => {
  const { data } = useQuery<any, Error>(
    "users",
    UserListApi,
    defaultUseQueryOption()
  );

  return (
    <Wrapper header="Users" title="Dashboard, Users">
      <Box p={20} className="rounded-lg bg-white shadow-2xl">
        <CommonTable
          data={data?.data}
          columns={columns}
          title=""
          keyField={"username"}
          link={{
            link: "/add-user",
            title: "Add User",
          }}
        />
      </Box>
    </Wrapper>
  );
};

export default Users;
