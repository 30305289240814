import { Box, Button, Checkbox, Group, HoverCard } from "@mantine/core";
import { useState } from "react";
import { MdSchedule } from "react-icons/md";
import { PiArrowsSplitFill } from "react-icons/pi";
import { RiMessage3Line } from "react-icons/ri";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import CustomFilterCommonTable from "../../components/CustomFilterCommonTable";
import Wrapper from "../../components/Wrapper";
import { convertTodt, defaultUseQueryOption, thousandK } from "../../helper";
import { ListDetailedReportApi } from "../../services";

const stagecolor = {
  submitted: "green",
  queued: "grey",
  completed: "darkgreen",
  scheduled: "orange",
  rescheduled: "darkorange",
  canceled: "orange",
  failed: "red",
  inprogress: "darkgoldenrod",
};

const campaigntype = {
  quick: "green",
  upload: "orange",
  dynamic: "royalblue",
};

const AccountOption = [
  {
    key: "#1",
    text: "Today",
    value: "today",
  },
  {
    key: "#5",
    text: "Yesterday",
    value: "yesterday",
  },
  {
    key: "#2",
    text: "Last 7 Days",
    value: "last7",
  },
  {
    key: "#3",
    text: "Last 30 Days",
    value: "last30",
  },
  {
    key: "#4",
    text: "Custom",
    value: "range",
  },
];

const Actions = (_cell: any, row: any) => {
  return <Link to={`/trxreport/${row.id}`}>View</Link>;
};

const DateFormatter = (cell: any, _row: any) => {
  const dt = convertTodt(cell);
  return <span>{`${dt.date} ${dt.time}`}</span>;
};

const MessageFormatter = (cell: string, _row: any) => {
  return (
    <Group position="left">
      <HoverCard shadow="md" withArrow={true} position="bottom">
        <HoverCard.Target>
          <div className="flex cursor-pointer items-center justify-start gap-x-2 hover:underline">
            <RiMessage3Line color="#4e8ac0" />
            <span>{cell}</span>
          </div>
        </HoverCard.Target>
        <HoverCard.Dropdown>
          <div className="">
            <span className="text-sm">{cell}</span>
          </div>
        </HoverCard.Dropdown>
      </HoverCard>
    </Group>
  );
};

const SenderFormatter = (cell: any, row: any) => {
  return (
    <Group position="left">
      <HoverCard shadow="md" withArrow={true} position="bottom">
        <HoverCard.Target>
          <div className="cursor-pointer underline">{cell}</div>
        </HoverCard.Target>
        <HoverCard.Dropdown>
          <div className="grid grid-cols-2">
            <span className="text-xs">Entity Id</span>
            <span className="text-xs">{row?.entityid}</span>
            <span className="text-xs">Template Id</span>
            <span className="text-xs">{row.templateid}</span>
          </div>
        </HoverCard.Dropdown>
      </HoverCard>
    </Group>
  );
};

const SubbmittedFormatter = (cell: string, _row: any) => {
  const nm = thousandK(cell);
  return nm.aboveThousand ? (
    <Group position="center">
      <HoverCard shadow="md" withArrow={true} position="bottom">
        <HoverCard.Target>
          <div
            className={`${
              nm.aboveThousand ? "underline" : ""
            }  flex cursor-pointer items-center justify-center gap-x-2`}
          >
            <span>{nm.number}</span>
          </div>
        </HoverCard.Target>
        <HoverCard.Dropdown>
          <div className="">
            <span className="text-sm">{cell}</span>
          </div>
        </HoverCard.Dropdown>
      </HoverCard>
    </Group>
  ) : (
    <Group position="center">
      <span className="text-center">{cell}</span>
    </Group>
  );
};
type CellType =
  | "submitted"
  | "queued"
  | "completed"
  | "scheduled"
  | "rescheduled"
  | "canceled"
  | "failed"
  | "inprogress";
type CampaignType = "quick" | "upload" | "dynamic";

const StageFormatter = (cell: CellType, _row: any) => {
  return (
    <Group position="center">
      <HoverCard shadow="md" withArrow={true} position="bottom">
        <HoverCard.Target>
          <div
            style={{
              display: "inline-text",
              width: 20,
              height: 20,
              backgroundColor: stagecolor[cell as CellType],
              color: "white",
              padding: 3,
              textAlign: "center",
              borderRadius: 100,
            }}
          >
            {" "}
          </div>
        </HoverCard.Target>
        <HoverCard.Dropdown>
          <div className="">
            <span className="text-sm">{cell}</span>
          </div>
        </HoverCard.Dropdown>
      </HoverCard>
    </Group>
  );
};

const CampaignTypeFormatter = (cell: CampaignType, row: any) => {
  return (
    <div
      style={{
        display: "inline-text",
        backgroundColor: campaigntype[cell as CampaignType],
        color: "white",
        padding: 3,
        textAlign: "center",
        borderRadius: 4,
      }}
    >
      <div className="flex items-center justify-center gap-x-2">
        {row?.isscheduled ? (
          <span title="Scheduled">
            <MdSchedule color="white" size={19} />
          </span>
        ) : null}
        {row?.issplit ? (
          <span title={`Split: ${row?.split?.length}`}>
            <PiArrowsSplitFill color="white" size={19} />
          </span>
        ) : null}
        <span>{cell}</span>
      </div>
    </div>
  );
};

const TemplateFormatter = (cell: string, _row: any) => {
  return (
    <Group position="left">
      <HoverCard shadow="md" withArrow={true} position="bottom">
        <HoverCard.Target>
          <div className="flex cursor-pointer items-center justify-start gap-x-2 hover:underline">
            <span>{cell}</span>
          </div>
        </HoverCard.Target>
        <HoverCard.Dropdown>
          <div className="">
            <span className="text-sm">{cell}</span>
          </div>
        </HoverCard.Dropdown>
      </HoverCard>
    </Group>
  );
};

const columns = [
  {
    dataField: "createdat",
    text: "Date",
    headerAlign: "left",
    formatter: DateFormatter,
    classes: "min-w-[250px] w-[250px]",
  },
  {
    dataField: "sender",
    text: "Sender Id",
    headerAlign: "left",
    formatter: SenderFormatter,
    classes: "min-w-[105px] w-[105px]",
  },
  {
    dataField: "sms",
    text: "Message",
    headerAlign: "left",
    formatter: MessageFormatter,
    classes: "min-w-[250px] ",
  },
  {
    dataField: "title",
    text: "Template Name",
    headerAlign: "left",
    formatter: TemplateFormatter,
    classes: "min-w-[170px] ",
  },
  {
    dataField: "details.totalnumbers",
    text: "Submitted",
    headerAlign: "center",
    formatter: SubbmittedFormatter,
    classes: "min-w-[100px] w-[100px]",
  },

  {
    dataField: "type",
    text: "Campaign Type",
    headerAlign: "center",
    formatter: CampaignTypeFormatter,
    classes: "min-w-[150px] w-[150px]",
  },
  {
    dataField: "stage",
    text: "Stage",
    headerAlign: "center",
    formatter: StageFormatter,
    classes: "min-w-[70px] w-[70px]",
  },
  {
    dataField: "#",
    text: "#",
    headerAlign: "left",
    formatter: Actions,
  },
];

const DetailedReport = () => {
  const [filterF, setFilterF] = useState({
    day: "today",
    sender: "",
    from: "",
    to: "",
    status: "completed",
    scheduled: false,
  });
  const { data, refetch } = useQuery<any, Error>(
    "detailedreport",
    () =>
      ListDetailedReportApi(
        filterF?.day,
        filterF?.from,
        filterF?.to,
        filterF?.scheduled
      ),
    defaultUseQueryOption()
  );
  const onChangeHandler = (e: any, { value, name }: any = {}) => {
    const e_name = name ? name : e.currentTarget.name;
    const e_value = value ? value : e.currentTarget.value;

    setFilterF((prevState) => ({
      ...prevState,
      [e_name]: e_value ? e_value : "",
    }));
  };
  const reportSearch = async () => {
    console.log("filter ::", filterF);
    await refetch();
  };

  return (
    <Wrapper header="Detailed Report" title="Dashboard, Reports">
      <Box p={20} className="rounded-lg bg-white shadow-2xl">
        <div className="mb-10 flex items-end space-x-3">
          <div className="flex flex-col">
            <label>By Date</label>
            <select
              className="rounded-md border-2 border-gray-300  px-4 py-2 hover:border-black"
              onChange={onChangeHandler}
              value={filterF.day}
              name="day"
            >
              {AccountOption.map((a, i) => {
                return (
                  <option key={i} value={a?.value}>
                    {a?.text}
                  </option>
                );
              })}
            </select>
          </div>
          {filterF.day === "range" ? (
            <>
              <div className="flex flex-col">
                <label>From</label>
                <input
                  onChange={onChangeHandler}
                  name="from"
                  type="date"
                  className="rounded-md border-2 border-gray-300  px-4 py-2 hover:border-black"
                />
              </div>
              <div className="flex flex-col">
                <label>To</label>
                <input
                  onChange={onChangeHandler}
                  type="date"
                  name="to"
                  className="rounded-md border-2 border-gray-300  px-4 py-2 hover:border-black"
                />
              </div>
            </>
          ) : null}

          <div className="mb-3">
            <Checkbox
              checked={filterF?.scheduled || false}
              onChange={(e) =>
                setFilterF((prevState) => ({
                  ...prevState,
                  scheduled: !filterF?.scheduled,
                }))
              }
              label="Scheduled"
            />
          </div>
          <Button
            size="md"
            variant="filled"
            radius={"md"}
            onClick={reportSearch}
            className="bg-gradient-to-br from-[#4d88c4] to-[#589bd6] px-3 py-3 hover:bg-gradient-to-r hover:from-[#589bd6] hover:to-[#4d88c4]"
          >
            Search
          </Button>
        </div>
        <CustomFilterCommonTable
          data={data?.data || []}
          columns={columns}
          keyField={"title"}
        />
      </Box>
    </Wrapper>
  );
};

export default DetailedReport;
