//@ts-nocheck

import BootstrapTable from "react-bootstrap-table-next";

import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

const CustomFilterCommonTable = ({
  columns,
  data,
  keyField,
}: {
  columns: any;
  data: any;
  keyField: string;
}) => {
  return (
    <ToolkitProvider
      search
      data={data || []}
      columns={columns}
      keyField={keyField}
      // title={title}
      exportCSV
    >
      {(props: any) => (
        <div className="rounded-lg  bg-[#e8eff7] shadow">
          <div className="flex flex-col p-5 md:flex-row md:items-center md:justify-between">
            <h3 className="mb-2 text-lg font-semibold"> </h3>
            <div className="s_bar space-3 flex flex-col items-center justify-center md:flex-row  md:space-x-3 md:space-y-0">
              <SearchBar
                className=" rounded-full border-2 border-gray-300  px-4 py-2 hover:border-black"
                placeholder="Search..."
                {...props.searchProps}
              />
              <ExportCSVButton {...props.csvProps}>Export</ExportCSVButton>
            </div>
          </div>
          <div className="relative  max-h-[calc(100vh-350px)]   overflow-x-auto ">
            <BootstrapTable
              classes="w-full"
              {...props.baseProps}
              // pagination={paginationFactory()}
            />
          </div>
        </div>
      )}
    </ToolkitProvider>

    // <div className="relative  max-h-[calc(100vh-450px)] overflow-x-auto ">
    //   <BootstrapTable
    //     data={data || []}
    //     columns={columns}
    //     keyField={keyField}
    //     classes="w-full"
    //     noDataIndication={() => (
    //       <div className="text-center">No campaign found</div>
    //     )}
    //   />
    // </div>
  );
};

export default CustomFilterCommonTable;
