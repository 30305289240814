import { Box, Modal } from "@mantine/core";
import { useState } from "react";
import { RiDeleteBin2Line } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import CommonTable from "../../components/CommonTable";
import Wrapper from "../../components/Wrapper";
import { defaultUseQueryOption } from "../../helper";
import { DeleteEntityApi, ListEntityApi } from "../../services";



const Entities = () => {
  const { data: entities, refetch } = useQuery(
    "entities",
    ListEntityApi,
    defaultUseQueryOption()
  );

  const [opened, setOpened] = useState(false);
  const [delId, setDelId] = useState(0);

  const { mutate: deleteE,isLoading } = useMutation(DeleteEntityApi, {
    onSuccess: (data) => {
      refetch()
      setOpened(!opened)
      setDelId(0)
    },
  });

  const Actions = (_cell: any, row: any) => {
    return (
      <div role="button" onClick={() => {
        setOpened(true)
        setDelId(row?.id)
      }} >
        <RiDeleteBin2Line color="red" />
      </div>
    );
  };

  const columns = [
    {
      dataField: "sender",
      text: "Sender Id",
      headerAlign: "left",
    },
    {
      dataField: "entity",
      text: "Entity",
      headerAlign: "left",
    },
    {
      dataField: "header",
      text: "Header",
      headerAlign: "left",
    },
    {
      dataField: "type",
      text: "Content Template Type",
      headerAlign: "left",
    },
    {
      dataField: "#",
      text: "#",
      headerAlign: "left",
      formatter: Actions,
    },
  ];

  return (
    <Wrapper header="Entities" title="DLT Managment, Entities">
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title="Delete Entity"
        withCloseButton={false}
      >
        <div>
          <p>All the associated data will be deleted and can not be recovered again. Are you sure do you want to delete ?</p>
          <div className="flex gap-x-2 justify-end mt-4" >
            <button onClick={() => deleteE({ id: delId })} className="border-[1px] rounded-md  px-3 bg-red-600 text-white py-1 border-red-600" >{isLoading ? "loading" : "Delete"}</button>
            <button onClick={() => setOpened(!opened)} className="border-[1px] rounded-md px-3 py-1 border-gray-500" >Cancel</button>
          </div>
        </div>
      </Modal>
      <Box p={20} className="rounded-lg bg-white shadow-2xl">
        <CommonTable
          data={entities?.data}
          columns={columns}
          title="Entities"
          keyField={"sender"}
          link={{
            link: "/add-entity",
            title: "Add Entity",
          }}
        />
      </Box>
    </Wrapper>
  );
};

export default Entities;
