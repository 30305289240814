//@ts-nocheck

import { Buffer } from "buffer";
import React, { createContext, useEffect, useState } from "react";

import { useLocalStorage } from "@mantine/hooks";
const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [localUser] = useLocalStorage({ key: "ux", defaultValue: "" });

  console.log("Auth Provider");

  useEffect(() => {
    const obj =
      localUser === ""
        ? { isauth: false }
        : {
            isauth: true,
            ...JSON.parse(Buffer.from(localUser!, "base64").toString()),
          };

    setUser({
      ...obj,
    });
  }, [localUser]);

  console.log(user);

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
