//@ts-nocheck

import { Box, Button, PasswordInput, Space, TextInput } from "@mantine/core";

import { useDocumentTitle } from "@mantine/hooks";
import { Buffer } from "buffer";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { LoginApi } from "../services";

const Login = () => {
  useDocumentTitle("Sinthan Techno - Login");
  const { user, setUser } = useAuth();

  const [authInput, setAuthInput] = useState({
    username: "",
    password: "",
  });
  const navigate = useNavigate();

  if (Object.keys(user).length === 0) {
    return null;
  }

  if (user.isauth) {
    return <Navigate replace to="/dashboard" />;
  }

  const LoginClick = async (e) => {
    e.preventDefault();
    await LoginApi({
      ...authInput,
    })
      .then((u) => {
        localStorage.setItem("ux", JSON.stringify(u.data.ux!));
        setUser((prev: any) => ({
          ...prev,
          ...JSON.parse(Buffer.from(u.data.ux!, "base64").toString()),
          isauth: true,
        }));
        navigate("/dashboard");
      })
      .catch((e) => alert(e?.message));
  };

  return (
    <Box className="bg flex h-full w-full  justify-center bg-[#4d88c4]">
      <div>
        <Space h={"xl"} />

        <Box
          p={20}
          className="min-w-[400px] rounded-lg bg-white py-10 shadow-2xl"
        >
          {/* <div className="mb-8 flex items-center justify-center p-4">
            <img
              src="https://sinthan.com/assets/images/logo.webp"
              alt="logo"
              style={{ width: 245 }}
            />
          </div> */}
          <Box
            component="form"
            onSubmit={LoginClick}
            className="bg-[#e8eff7] p-3"
          >
            <TextInput
              label="Username"
              placeholder="Your username"
              required
              onChange={(e) =>
                setAuthInput((prev) => ({
                  ...prev,
                  username: e.target.value,
                }))
              }
            />
            <PasswordInput
              onChange={(e) =>
                setAuthInput((prev) => ({
                  ...prev,
                  password: e.target.value,
                }))
              }
              label="Password"
              placeholder="Your password"
              required
              mt="md"
            />
            <Space h={"xl"} />
            <Button
              type="submit"
              className="bg-gradient-to-br from-[#4d88c4] to-[#589bd6]  hover:bg-gradient-to-r hover:from-[#589bd6] hover:to-[#4d88c4]"
              size="md"
              fullWidth
            >
              Login
            </Button>
          </Box>
        </Box>
      </div>
    </Box>
  );
};

export default Login;
