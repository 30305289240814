import {
  Box,
  Button,
  Space,
  Switch,
  Tabs,
  TextInput,
  Textarea,
  Tooltip,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import _ from "lodash";
import { useEffect, useState } from "react";
import { RiUser2Line, RiUserUnfollowLine } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import UserInfoDetails from "../../components/UserInfoDetails";
import Wrapper from "../../components/Wrapper";
import { defaultUseQueryOption, validNumber } from "../../helper";
import { EditUserCpApi, EditUserNmApi, UserByIdApi } from "../../services";

const UserDetails = () => {
  const params = useParams();
  const { data, isLoading: loaduser } = useQuery<any, Error>(
    "userByid",
    () => UserByIdApi(params?.id || "", "cp"),
    defaultUseQueryOption()
  );
  const [capping, setCapping] = useState({
    capping: false,
    capabove: 1000,
    cappercent: 10,
  });

  console.log("loaduser::", data);

  const [numbers, setNumbers] = useState<string>();
  const [numberCount, setNumberCount] = useState<string>("0 Number(s)");

  useEffect(() => {
    if (!loaduser) {
      setCapping({
        capping: data?.data?.iscapping,
        capabove: data?.data?.misc?.capabove || 1000,
        cappercent: data?.data?.misc?.cappercent || 10,
      });
      setNumbers(data?.data?.numbers?.join("\n"));
      setNumberCount(
        `${data?.data?.numbers?.length} Numbers |${data?.data?.numbers?.length} Valid, 0 Dupicate`
      );
    }
  }, [data, loaduser]);

  const { mutate: usercp, isLoading: loadCp } = useMutation(EditUserCpApi, {
    onSuccess: (data) => {
      showNotification({
        autoClose: 5000,
        title: "Capping",
        message: "User Capping successfully done",
        color: "green",
      });
    },
    onError: (err) => {
      showNotification({
        autoClose: 5000,
        title: "Capping Error",
        message: "Something went wrong, please try again",
        color: "green",
      });
    },
  });

  const { mutate: usernm, isLoading: loadNm } = useMutation(EditUserNmApi, {
    onSuccess: (data) => {
      const valid_contacts = _.filter(_.split(numbers, "\n"), (o) =>
        validNumber(o)
      );
      const uniq = _.uniq(valid_contacts);
      setNumbers(uniq.join("\n"));
      setNumberCount(
        `${uniq.length} Numbers |${valid_contacts.length} Valid, 0 Dupicate`
      );
      showNotification({
        autoClose: 5000,
        title: "Numbers",
        message: "User numbers uploaded successfully done",
        color: "green",
      });
    },
  });

  const updateHandleCp = (event: any) => {
    const _name = event.target.name;
    const _value =
      _name === "capping" ? event.target.checked : event.target.value;

    setCapping((prev) => ({
      ...prev,
      [_name]: _value,
    }));
  };

  const onUpdateCp = async () => {
    console.log("-->", capping);
    usercp({
      ...capping,
      id: params?.id,
    });
  };

  const onUpdateNm = async () => {
    console.log("-->", capping);
    const valid_contacts = _.filter(_.split(numbers, "\n"), (o) =>
      validNumber(o)
    );
    usernm({
      numbers: _.uniq(valid_contacts),
      id: params?.id,
    });
  };

  const onChangeNumber = (value: any) => {
    const numbers = value;
    const nm_list = _.split(numbers, "\n", 10000);
    setNumbers(nm_list.join("\n"));

    const valid_contacts = _.filter(_.split(numbers, "\n"), (o) =>
      validNumber(o)
    );
    const noduplicate = _.uniq(valid_contacts).length;

    setNumberCount(
      `${nm_list.length} Numbers |${valid_contacts.length} Valid, ${
        valid_contacts.length - noduplicate
      } Dupicate`
    );
  };

  return (
    <Wrapper header="User Details" title="Dashboard,Users, User Details">
      {loaduser ? (
        <div>Loading...</div>
      ) : (
        <Box p={20} className="rounded-lg bg-white shadow-2xl">
          <Box className="rounded-lg bg-[#e8eff7]">
            <Tabs defaultValue="user">
              <Tabs.List>
                <Tabs.Tab value="user" icon={<RiUser2Line size={14} />}>
                  Details
                </Tabs.Tab>
                <Tabs.Tab value="password" icon={<RiUser2Line size={14} />}>
                  Change Password
                </Tabs.Tab>
                <Tabs.Tab value="cap" icon={<RiUserUnfollowLine size={14} />}>
                  Capping Details
                </Tabs.Tab>
              </Tabs.List>
              <Tabs.Panel value="user" pt="xs">
                <UserInfoDetails user={data?.data} />
              </Tabs.Panel>
              <Tabs.Panel value="password" pt="xs">
                <div className=" flex flex-col space-y-5 p-5">
                  <div className="flex flex-row flex-wrap items-center">
                    <div className="basis-full md:basis-1/3">New Password</div>
                    <div className="basis-full md:basis-1/2">
                      <TextInput placeholder="New Password" size="md" />
                    </div>
                  </div>
                  <div>
                    <Space h={"md"} />
                    <div className="flex flex-row flex-wrap items-center">
                      <div className="basis-full md:basis-1/3"></div>
                      <div className="basis-full space-x-3 md:basis-1/2 ">
                        <Button
                          size="md"
                          variant="filled"
                          className="bg-gradient-to-br from-[#4d88c4] to-[#589bd6] px-7 py-3 hover:bg-gradient-to-r hover:from-[#589bd6] hover:to-[#4d88c4]"
                          type="submit"
                          //   loading={isLoading}
                        >
                          <span className="ml-2">Change Password</span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Tabs.Panel>
              <Tabs.Panel value="cap" pt="xs">
                <h3 className="mb-4 px-5 text-lg font-semibold">
                  Username: {data?.data?.username}
                </h3>
                <div className="flex">
                  <div className="flex-1 px-5">
                    <h3 className="mb-4 font-semibold underline">
                      Capping Info
                    </h3>
                    {!loaduser ? (
                      <div className="grid grid-cols-2 gap-y-4">
                        <span>Is Capping</span>
                        <span>
                          <Switch
                            onChange={updateHandleCp}
                            name="capping"
                            checked={capping.capping}
                          />
                        </span>
                        <span>Capping when number &gt; </span>
                        <span>
                          <TextInput
                            type="number"
                            onChange={updateHandleCp}
                            name="capabove"
                            value={capping.capabove}
                          />
                        </span>
                        <span>Capping % </span>
                        <span>
                          <TextInput
                            type="number"
                            onChange={updateHandleCp}
                            name="cappercent"
                            value={capping.cappercent}
                          />
                        </span>
                        <span></span>
                        <div>
                          <Button
                            size="md"
                            variant="filled"
                            className="bg-gradient-to-br from-[#4d88c4] to-[#589bd6] px-3 py-3 hover:bg-gradient-to-r hover:from-[#589bd6] hover:to-[#4d88c4]"
                            onClick={onUpdateCp}
                            loading={loadCp}
                          >
                            Update
                          </Button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="flex-1 px-5">
                    <h3 className="mb-4 font-semibold underline">Numbers</h3>
                    <div className="flex flex-col gap-y-4">
                      <div>
                        <Textarea
                          placeholder="Mobile Number(s)"
                          size="md"
                          minRows={15}
                          maxRows={15}
                          spellCheck={false}
                          value={numbers}
                          onChange={(event) =>
                            onChangeNumber(event.currentTarget.value)
                          }
                        />
                        <div className="flex justify-between">
                          <span className="p-1 font-mono text-sm text-gray-400">
                            Note: Enter mobile numbers (new-line seprated)
                          </span>
                          <span className="p-1 font-mono text-sm text-gray-400">
                            <Tooltip
                              color="blue"
                              withArrow
                              label={numberCount.split("|")[1]}
                            >
                              <span
                                className={`${
                                  numberCount.split("|").length > 1
                                    ? "cursor-pointer underline"
                                    : ""
                                }`}
                              >
                                {numberCount.split("|")[0]}
                              </span>
                            </Tooltip>
                          </span>
                        </div>
                      </div>
                      <div>
                        <Button
                          size="md"
                          variant="filled"
                          className="bg-gradient-to-br from-[#4d88c4] to-[#589bd6] px-3 py-3 hover:bg-gradient-to-r hover:from-[#589bd6] hover:to-[#4d88c4]"
                          onClick={onUpdateNm}
                          loading={loadNm}
                        >
                          Update
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Tabs.Panel>
            </Tabs>
          </Box>
        </Box>
      )}
    </Wrapper>
  );
};

export default UserDetails;

//Message submitted to platform. Total Number Uploaded :- 5 .Duplicate/Incorrect Number Count :- 4 .Number submitted to platform:- 1
