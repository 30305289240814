import { Box } from "@mantine/core";
import { RiDeleteBin2Line } from "react-icons/ri";
import { useQuery } from "react-query";
import CustomFilterCommonTable from "../../components/CustomFilterCommonTable";
import Wrapper from "../../components/Wrapper";
import { defaultUseQueryOption } from "../../helper";
import { ListInvoiceApi } from "../../services";

const Actions = (_cell: any, _row: any) => {
  return (
    <div>
      <RiDeleteBin2Line color="red" />
    </div>
  );
};

const columns = [
  {
    dataField: "user.username",
    text: "Username",
    headerAlign: "left",
  },
  {
    dataField: "user.fullname",
    text: "Full Name",
    headerAlign: "left",
  },
  {
    dataField: "user.phone",
    text: "Phone",
    headerAlign: "left",
  },
  {
    dataField: "user.role",
    text: "Role",
    headerAlign: "left",
  },

  {
    dataField: "credit",
    text: "Credit",
    headerAlign: "left",
  },

  {
    dataField: "type",
    text: "Type",
    headerAlign: "left",
  },
  // {
  //   dataField: "#",
  //   text: "#",
  //   headerAlign: "left",
  //   formatter: Actions,
  // },
];

const CreditHistory = () => {
  const { data } = useQuery<any, Error>(
    "users",
    ListInvoiceApi,
    defaultUseQueryOption()
  );

  return (
    <Wrapper header="Credit History" title="Dashboard, Credit History">
      <Box p={20} className="rounded-lg bg-white shadow">
        <Box className="rounded-lg bg-[#e8eff7] ">
          {/* <div className="flex w-full items-end space-x-6 p-5">
            <Select
              width={500}
              size="md"
              radius={"xl"}
              label="Username"
              placeholder="Pick one"
              searchable
              data={[
                { value: "react", label: "React" },
                { value: "ng", label: "Angular" },
                { value: "svelte", label: "Svelte" },
                { value: "vue", label: "Vue" },
              ]}
            />
            <DatePicker
              placeholder="Click to select from date"
              label="Form"
              radius={"xl"}
              size="md"
            />

            <DatePicker
              placeholder="Click to select to date"
              label="To"
              radius={"xl"}
              size="md"
            />
            <Button
              radius={"xl"}
              size="md"
              variant="filled"
              className="bg-gradient-to-br from-[#4d88c4] to-[#589bd6] px-3 py-3 hover:bg-gradient-to-r hover:from-[#589bd6] hover:to-[#4d88c4]"
              type="submit"
            >
              <FaSearch /> <span className="ml-2">Search</span>
            </Button>
          </div> */}

          <div className="relative  max-h-[calc(100vh-350px)]   overflow-x-auto ">
            <CustomFilterCommonTable
              data={data?.data}
              columns={columns}
              keyField={"username"}
            />
          </div>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default CreditHistory;
