import {
  Box,
  Button,
  Checkbox,
  Radio,
  Space,
  Switch,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { FaSave } from "react-icons/fa";
import { useMutation } from "react-query";
import Wrapper from "../../components/Wrapper";
import { generatePassword } from "../../helper";
import { IUserInput, userSchema } from "../../schema";
import { RegisterApi } from "../../services";

const AddUser = () => {
  const { mutate, isLoading } = useMutation(RegisterApi, {
    onSuccess: (data) => {
      form.reset();
      showNotification({
        autoClose: 5000,
        title: "Create user",
        message: "User Add successfully done",
        color: "green",
      });
    },
    onError: () => {
      showNotification({
        autoClose: 5000,
        title: "Create user",
        message: "Something went wrong, please try again",
        color: "red",
      });
    },
  });

  const form = useForm<IUserInput>({
    validate: zodResolver(userSchema),
    initialValues: {
      username: "",
      fullname: "",
      password: generatePassword(),
      role: "client",
      misc: {
        usertype: ["web"],
      },
      reseller: {
        domain: "sinthan",
      },
      canaddvirtualcredit: false,
      viewcredithistory: false,
      status: "active",
    },
  });

  const onAddUser = (values: IUserInput) => {
    const data = {
      ...values,
    };

    if (data.role === "client") {
      delete data.reseller;
    }

    mutate(data);
  };

  return (
    <Wrapper header="Add User" title="Dashboard,Users, Add User">
      <Box p={20} className="rounded-lg bg-white shadow-2xl">
        <Box
          component="form"
          onSubmit={form.onSubmit(onAddUser)}
          className="rounded-lg bg-[#e8eff7] "
        >
          <div className=" flex flex-col space-y-5 p-5">
            <div className="flex flex-row flex-wrap items-center">
              <div className="basis-full md:basis-1/3">Username</div>
              <div className="basis-full md:basis-1/2">
                <TextInput
                  placeholder="Username"
                  size="md"
                  {...form.getInputProps("username")}
                />
              </div>
            </div>

            <div className="flex flex-row flex-wrap items-center">
              <div className="basis-full md:basis-1/3">Full Name</div>
              <div className="basis-full md:basis-1/2">
                <TextInput
                  placeholder="Full Name"
                  size="md"
                  {...form.getInputProps("fullname")}
                />
              </div>
            </div>

            <div className="flex flex-row flex-wrap items-center">
              <div className="basis-full md:basis-1/3">Mobile Number</div>
              <div className="basis-full md:basis-1/2">
                <TextInput
                  placeholder="Mobile Number"
                  size="md"
                  {...form.getInputProps("phone")}
                  value={form.values.phone}
                />
              </div>
            </div>

            <div className="flex flex-row flex-wrap items-center">
              <div className="basis-full md:basis-1/3">Email</div>
              <div className="basis-full md:basis-1/2">
                <TextInput
                  placeholder="Email"
                  size="md"
                  {...form.getInputProps("email")}
                  value={form.values.email}
                />
              </div>
            </div>

            <div className="flex flex-row flex-wrap items-center">
              <div className="basis-full md:basis-1/3">Password</div>
              <div className="basis-full md:basis-1/2">
                <TextInput
                  placeholder="Password"
                  size="md"
                  {...form.getInputProps("password")}
                />
              </div>
            </div>

            <div className="flex flex-row flex-wrap items-center">
              <div className="basis-full md:basis-1/3">Role</div>
              <div className="basis-full md:basis-1/2">
                <Radio.Group name="unicode" {...form.getInputProps("role")}>
                  <Radio value="client" label="Client" />
                  <Radio value="reseller" label="Reseller" />
                </Radio.Group>
              </div>
            </div>

            {form.values.role === "reseller" && (
              <>
                <Text size="md" className="font-semibold">
                  Reseller Details
                </Text>
                <div className="flex flex-row flex-wrap items-center">
                  <div className="basis-full md:basis-1/3">Domain</div>
                  <div className="basis-full md:basis-1/2">
                    <TextInput
                      placeholder="Domain Url"
                      size="md"
                      {...form.getInputProps("reseller.domain")}
                    />
                  </div>
                </div>
                <div className="flex flex-row flex-wrap items-center">
                  <div className="basis-full md:basis-1/3">
                    Can add virtual SMS to there client(s)
                  </div>
                  <div className="basis-full md:basis-1/2">
                    <Switch
                      size="md"
                      onLabel="Yes"
                      offLabel="No"
                      {...form.getInputProps("canaddvirtualcredit")}
                      defaultChecked={form.values.canaddvirtualcredit}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="flex flex-row flex-wrap items-center">
              <div className="basis-full md:basis-1/3">View Credit History</div>
              <div className="basis-full md:basis-1/2">
                <Switch
                  size="md"
                  onLabel="Yes"
                  offLabel="No"
                  {...form.getInputProps("viewcredithistory")}
                  defaultChecked={form.values.viewcredithistory}
                />
              </div>
            </div>
            {/* <div className="flex flex-row flex-wrap items-center">
            <div className="basis-full md:basis-1/3">View Invoice</div>
            <div className="basis-full md:basis-1/2">
              <Switch
                size="md"
                onLabel="Yes"
                offLabel="No"
                {...form.getInputProps("misc.isInvoiceView")}
              />
            </div>
          </div> */}

            <div className="flex flex-row flex-wrap items-center">
              <div className="basis-full md:basis-1/3">User Type</div>
              <div className="basis-full md:basis-1/2">
                <Checkbox.Group
                  defaultValue={form.values.misc.usertype}
                  {...form.getInputProps("misc.usertype")}
                >
                  <Checkbox value="web" label="Web" />
                  <Checkbox value="api" label="Api" />
                </Checkbox.Group>
              </div>
            </div>

            <div>
              <Space h={"md"} />
              <div className="flex flex-row flex-wrap items-center">
                <div className="basis-full md:basis-1/3"></div>
                <div className="basis-full space-x-3 md:basis-1/2 ">
                  <Button
                    size="md"
                    variant="filled"
                    className="bg-gradient-to-br from-[#4d88c4] to-[#589bd6] px-7 py-3 hover:bg-gradient-to-r hover:from-[#589bd6] hover:to-[#4d88c4]"
                    type="submit"
                    loading={isLoading}
                  >
                    <FaSave /> <span className="ml-2">Submit</span>
                  </Button>
                  <Button size="md" variant="outline" color={"#4d88c4"}>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default AddUser;

//Message submitted to platform. Total Number Uploaded :- 5 .Duplicate/Incorrect Number Count :- 4 .Number submitted to platform:- 1
