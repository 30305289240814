import { Box, Button, Group, HoverCard, Modal } from "@mantine/core";
import { useState } from "react";
import { MdSchedule } from "react-icons/md";
import { PiArrowsSplitFill, PiEye, PiTrash } from "react-icons/pi";
import { RiMessage3Line } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import { Link } from "react-router-dom";
import CustomFilterCommonTable from "../../components/CustomFilterCommonTable";
import Wrapper from "../../components/Wrapper";
import { convertTodt, defaultUseQueryOption, thousandK } from "../../helper";
import { DeleteScheduledApi, ListScheduledReportApi } from "../../services";

const stagecolor = {
  submitted: "green",
  queued: "grey",
  completed: "darkgreen",
  scheduled: "orange",
  rescheduled: "darkorange",
  cancelled: "red",
  failed: "red",
  inprogress: "darkgoldenrod",
};

const campaigntype = {
  quick: "green",
  upload: "orange",
  dynamic: "royalblue",
};

const AccountOption = [
  {
    key: "#1",
    text: "Today",
    value: "today",
  },
  {
    key: "#5",
    text: "Yesterday",
    value: "yesterday",
  },
  {
    key: "#2",
    text: "Last 7 Days",
    value: "last7",
  },
  {
    key: "#3",
    text: "Last 30 Days",
    value: "last30",
  },
  {
    key: "#4",
    text: "Custom",
    value: "range",
  },
];

const DateFormatter = (cell: any, row: any) => {
  const dt = convertTodt(cell);
  const s_dt = convertTodt(row?.scheduledat);
  return (
    <div className="flex flex-col space-y-2">
      <span>{`${dt.date} ${dt.time}`}</span>
      <span className="text-green-400">{`${s_dt.date} ${s_dt.time}`}</span>
    </div>
  );
};

const MessageFormatter = (cell: string, _row: any) => {
  return (
    <Group position="left">
      <HoverCard shadow="md" withArrow={true} position="bottom">
        <HoverCard.Target>
          <div className="flex cursor-pointer items-center justify-start gap-x-2 hover:underline">
            <RiMessage3Line color="#4e8ac0" />
            <span>{cell}</span>
          </div>
        </HoverCard.Target>
        <HoverCard.Dropdown>
          <div className="">
            <span className="text-sm">{cell}</span>
          </div>
        </HoverCard.Dropdown>
      </HoverCard>
    </Group>
  );
};

const SenderFormatter = (cell: any, row: any) => {
  return (
    <Group position="left">
      <HoverCard shadow="md" withArrow={true} position="bottom">
        <HoverCard.Target>
          <div className="cursor-pointer underline">{cell}</div>
        </HoverCard.Target>
        <HoverCard.Dropdown>
          <div className="grid grid-cols-2">
            <span className="text-xs">Entity Id</span>
            <span className="text-xs">{row?.entityid}</span>
            <span className="text-xs">Template Id</span>
            <span className="text-xs">{row.templateid}</span>
          </div>
        </HoverCard.Dropdown>
      </HoverCard>
    </Group>
  );
};

const SubbmittedFormatter = (cell: string, _row: any) => {
  const nm = thousandK(cell);
  return nm.aboveThousand ? (
    <Group position="center">
      <HoverCard shadow="md" withArrow={true} position="bottom">
        <HoverCard.Target>
          <div
            className={`${
              nm.aboveThousand ? "underline" : ""
            }  flex cursor-pointer items-center justify-center gap-x-2`}
          >
            <span>{nm.number}</span>
          </div>
        </HoverCard.Target>
        <HoverCard.Dropdown>
          <div className="">
            <span className="text-sm">{cell}</span>
          </div>
        </HoverCard.Dropdown>
      </HoverCard>
    </Group>
  ) : (
    <Group position="center">
      <span className="text-center">{cell}</span>
    </Group>
  );
};
type CellType =
  | "submitted"
  | "queued"
  | "completed"
  | "scheduled"
  | "rescheduled"
  | "cancelled"
  | "failed"
  | "inprogress";
type CampaignType = "quick" | "upload" | "dynamic";

const StageFormatter = (cell: CellType, _row: any) => {
  return (
    <Group position="center">
      <HoverCard shadow="md" withArrow={true} position="bottom">
        <HoverCard.Target>
          <div
            style={{
              display: "inline-text",
              width: 20,
              height: 20,
              backgroundColor: stagecolor[cell as CellType],
              color: "white",
              padding: 3,
              textAlign: "center",
              borderRadius: 100,
            }}
          >
            {" "}
          </div>
        </HoverCard.Target>
        <HoverCard.Dropdown>
          <div className="">
            <span className="text-sm">{cell}</span>
          </div>
        </HoverCard.Dropdown>
      </HoverCard>
    </Group>
  );
};

const CampaignTypeFormatter = (cell: CampaignType, row: any) => {
  return (
    <div
      style={{
        display: "inline-text",
        backgroundColor: campaigntype[cell as CampaignType],
        color: "white",
        padding: 3,
        textAlign: "center",
        borderRadius: 4,
      }}
    >
      <div className="flex items-center justify-center gap-x-2">
        {row?.isscheduled ? (
          <span title="Scheduled">
            <MdSchedule color="white" size={19} />
          </span>
        ) : null}
        {row?.issplit ? (
          <span title={`Split: ${row?.split?.length}`}>
            <PiArrowsSplitFill color="white" size={19} />
          </span>
        ) : null}
        <span>{cell}</span>
      </div>
    </div>
  );
};

const TemplateFormatter = (cell: string, _row: any) => {
  return (
    <Group position="left">
      <HoverCard shadow="md" withArrow={true} position="bottom">
        <HoverCard.Target>
          <div className="flex cursor-pointer items-center justify-start gap-x-2 hover:underline">
            <span>{cell}</span>
          </div>
        </HoverCard.Target>
        <HoverCard.Dropdown>
          <div className="">
            <span className="text-sm">{cell}</span>
          </div>
        </HoverCard.Dropdown>
      </HoverCard>
    </Group>
  );
};

const ScheduledReport = () => {
  const [filterF, setFilterF] = useState({
    day: "today",
    sender: "",
    from: "",
    to: "",
    status: "completed",
  });

  const Actions = (_cell: any, row: any) => {
    return (
      <div className="flex items-center justify-center space-x-2 ">
        <Link to={`/trxreport/${row.id}`}>
          <PiEye size={19} />
        </Link>
        {row?.stage === "queued" && (
          <>
            <span>|</span>
            <div
              role="button"
              onClick={() => {
                setOpened(true);
                setDelId(row?.id);
              }}
            >
              <PiTrash color="red" size={19} />
            </div>
          </>
        )}
      </div>
    );
  };

  const columns = [
    {
      dataField: "createdat",
      text: "Date/Scheduled At",
      headerAlign: "left",
      formatter: DateFormatter,
      classes: "min-w-[250px] w-[250px]",
    },
    {
      dataField: "sender",
      text: "Sender Id",
      headerAlign: "left",
      formatter: SenderFormatter,
      classes: "min-w-[105px] w-[105px]",
    },
    {
      dataField: "sms",
      text: "Message",
      headerAlign: "left",
      formatter: MessageFormatter,
      classes: "min-w-[250px] ",
    },
    {
      dataField: "title",
      text: "Template Name",
      headerAlign: "left",
      formatter: TemplateFormatter,
      classes: "min-w-[170px] ",
    },
    {
      dataField: "details.totalnumbers",
      text: "Submitted",
      headerAlign: "center",
      formatter: SubbmittedFormatter,
      classes: "min-w-[100px] w-[100px]",
    },

    {
      dataField: "type",
      text: "Campaign Type",
      headerAlign: "center",
      formatter: CampaignTypeFormatter,
      classes: "min-w-[150px] w-[150px]",
    },
    {
      dataField: "stage",
      text: "Stage",
      headerAlign: "center",
      formatter: StageFormatter,
      classes: "min-w-[70px] w-[70px]",
    },
    {
      dataField: "#",
      text: "#",
      headerAlign: "left",
      formatter: Actions,
    },
  ];

  const [opened, setOpened] = useState(false);
  const [delId, setDelId] = useState(0);
  const { data, refetch } = useQuery<any, Error>(
    "scheduledreport",
    () => ListScheduledReportApi(filterF?.day, filterF?.from, filterF?.to),
    defaultUseQueryOption()
  );
  const onChangeHandler = (e: any, { value, name }: any = {}) => {
    const e_name = name ? name : e.currentTarget.name;
    const e_value = value ? value : e.currentTarget.value;

    setFilterF((prevState) => ({
      ...prevState,
      [e_name]: e_value ? e_value : "",
    }));
  };
  const reportSearch = async () => {
    console.log("filter ::", filterF);
    await refetch();
  };

  const { mutate: deleteE, isLoading } = useMutation(DeleteScheduledApi, {
    onSuccess: (data) => {
      refetch();
      setOpened(!opened);
      setDelId(0);
    },
  });

  return (
    <Wrapper header="Scheduled Report" title="Dashboard, Reports">
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title="Delete Entity"
        withCloseButton={false}
      >
        <div>
          <p>Are you sure do you want to delete ?</p>
          <div className="mt-4 flex justify-end gap-x-2">
            <button
              onClick={() => deleteE({ id: delId })}
              className="rounded-md border-[1px]  border-red-600 bg-red-600 px-3 py-1 text-white"
            >
              {isLoading ? "loading" : "Delete"}
            </button>
            <button
              onClick={() => setOpened(!opened)}
              className="rounded-md border-[1px] border-gray-500 px-3 py-1"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <Box p={20} className="rounded-lg bg-white shadow-2xl">
        <div className="mb-10 flex items-end space-x-3">
          <div className="flex flex-col">
            <label>By Date</label>
            <select
              className="rounded-md border-2 border-gray-300  px-4 py-2 hover:border-black"
              onChange={onChangeHandler}
              value={filterF.day}
              name="day"
            >
              {AccountOption.map((a, i) => {
                return (
                  <option key={i} value={a?.value}>
                    {a?.text}
                  </option>
                );
              })}
            </select>
          </div>
          {filterF.day === "range" ? (
            <>
              <div className="flex flex-col">
                <label>From</label>
                <input
                  onChange={onChangeHandler}
                  name="from"
                  type="date"
                  className="rounded-md border-2 border-gray-300  px-4 py-2 hover:border-black"
                />
              </div>
              <div className="flex flex-col">
                <label>To</label>
                <input
                  onChange={onChangeHandler}
                  type="date"
                  name="to"
                  className="rounded-md border-2 border-gray-300  px-4 py-2 hover:border-black"
                />
              </div>
            </>
          ) : null}

          <Button
            size="md"
            variant="filled"
            radius={"md"}
            onClick={reportSearch}
            className="bg-gradient-to-br from-[#4d88c4] to-[#589bd6] px-3 py-3 hover:bg-gradient-to-r hover:from-[#589bd6] hover:to-[#4d88c4]"
          >
            Search
          </Button>
        </div>
        <CustomFilterCommonTable
          data={data?.data || []}
          columns={columns}
          keyField={"title"}
        />
      </Box>
    </Wrapper>
  );
};

export default ScheduledReport;
