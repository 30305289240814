import { Box, Group, HoverCard } from "@mantine/core";
import { RiMessage3Line } from "react-icons/ri";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import CommonTable from "../../components/CommonTable";
import Wrapper from "../../components/Wrapper";
import {
  convertTodt,
  defaultUseQueryOption,
  doneDateFormattedDateTime,
} from "../../helper";
import { ReportByTrxApi } from "../../services";

const DateFormatter = (cell: any, _row: any) => {
  const dt = convertTodt(cell);
  return <span>{`${dt.date} ${dt.time}`}</span>;
};

const MessageIdFormatter = (cell: string, _row: any) => {
  return <span>{cell.replace(/-/g, "").toUpperCase()}</span>;
};

const DoneDateFormatter = (cell: any, _row: any) => {
  const dt = doneDateFormattedDateTime(cell);
  return <span>{dt}</span>;
};

const StatusFormatter = (cell: string, row: any) => {
  return (
    <Group position="left">
      <HoverCard shadow="md" withArrow={true} position="bottom">
        <HoverCard.Target>
          <div className="flex cursor-pointer items-center justify-start gap-x-2 hover:underline">
            <RiMessage3Line color="#4e8ac0" />
            <span>{cell}</span>
          </div>
        </HoverCard.Target>
        <HoverCard.Dropdown>
          <div className="">
            <span className="text-sm">{row?.status}</span>
          </div>
        </HoverCard.Dropdown>
      </HoverCard>
    </Group>
  );
};

const columns = [
  {
    dataField: "createdat",
    text: "Date",
    headerAlign: "left",
    formatter: DateFormatter,
    classes: "min-w-[250px] w-[250px]",
  },
  {
    dataField: "id",
    text: "Message Id",
    formatter: MessageIdFormatter,
    headerAlign: "left",
  },
  {
    dataField: "number",
    text: "Number",
    headerAlign: "left",
  },

  {
    dataField: "donedate",
    text: "Done Date",
    formatter: DoneDateFormatter,
    headerAlign: "left",
  },
  // {
  //   dataField: "iscapped",
  //   text: "Capped",
  //   headerAlign: "left",
  // },
  {
    dataField: "stat",
    text: "Status",
    headerAlign: "left",
  },
  {
    dataField: "statuscode",
    text: "Status Code",
    headerAlign: "left",
    classes: "min-w-[110px]",
    formatter: StatusFormatter,
  },
];

const ReportByTrxSummary = () => {
  const params = useParams();
  const { data } = useQuery<any, Error>(
    "reporttrx",
    () => ReportByTrxApi(params?.id || ""),
    defaultUseQueryOption()
  );

  console.log("repoart trx summary :->", data);

  return (
    <Wrapper header="Campaign detailed report" title="Dashboard, Reports">
      <Box p={20} className="rounded-lg bg-white shadow-2xl">
        {/* <div className="mb-10 flex items-end space-x-3">
                    <div className="flex flex-col">
                        <label>Sender Id</label>
                        <select
                            placeholder=""
                            className="rounded-md border-2 border-gray-300  px-4 py-2 hover:border-black"
                            onChange={(e) => setSender(e.target.value)}
                        >
                            <option value="--select--">-- select sender id -- </option>
                            {Object.keys(_.groupBy(tabl, "senderid")).map((a, i) => {
                                return <option value={a}>{a}</option>;
                            })}
                            <option value="all">All</option>
                        </select>
                    </div>
                    <div className="flex flex-col">
                        <label>From</label>
                        <input
                            onChange={(e) => setFromDate(e.target.value)}
                            type="date"
                            className="rounded-md border-2 border-gray-300  px-4 py-2 hover:border-black"
                        />
                    </div>
                    <div className="flex flex-col">
                        <label>To</label>
                        <input
                            onChange={(e) => setToDate(e.target.value)}
                            type="date"
                            className="rounded-md border-2 border-gray-300  px-4 py-2 hover:border-black"
                        />
                    </div>

                    <Button
                        size="md"
                        variant="filled"
                        radius={"md"}
                        onClick={reportSearch}
                        className="bg-gradient-to-br from-[#4d88c4] to-[#589bd6] px-3 py-3 hover:bg-gradient-to-r hover:from-[#589bd6] hover:to-[#4d88c4]"
                    >
                        Search
                    </Button>
                </div> */}

        <CommonTable
          data={data?.data || []}
          columns={columns}
          keyField={"title"}
          title={""}
          link={null}
        />
      </Box>
    </Wrapper>
  );
};

export default ReportByTrxSummary;
