export const defaultUseQueryOption = () => {
  return {
    retry: false,
    refetchOnWindowFocus: false,
  };
};

export const validNumber = (n: any) => {
  const rex = /^[6-9]\d{9}$/;
  return rex.test(n);
};

export const getInitials = function (value: string) {
  var names = value.split(" "),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};
const upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const specialChars = "!@#$%^&*";
const numbers = "0123456789";
const lowerChars = "abcdefghijklmnopqrstuvwxyz";
const allChars = upperChars + specialChars + numbers + lowerChars;

export const generatePassword = (): string => {
  let password = "";
  password += lowerChars[Math.floor(Math.random() * lowerChars.length)];
  password += numbers[Math.floor(Math.random() * numbers.length)];
  password += specialChars[Math.floor(Math.random() * specialChars.length)];

  while (password.length < 8) {
    password += allChars[Math.floor(Math.random() * allChars.length)];
  }
  password =
    password.slice(0, -1) +
    upperChars[Math.floor(Math.random() * upperChars.length)];
  return password;
};

export const getTimeInHourMinuteAMPM = () => {
  const currentTime = new Date();
  const hours = currentTime.getHours();
  const minutes = currentTime.getMinutes();

  // Determine AM or PM
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert 24-hour format to 12-hour format
  const formattedHours = hours % 12 || 12;

  // Add leading zero for minutes less than 10
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${formattedHours}:${formattedMinutes} ${ampm}`;
};

export const convertTodt = (dt: any) => {
  if (dt === "-") {
    return { date: "---", time: "---" };
  }

  let ndate = null;
  if (dt.length === 12) {
    let datesplit = dt.match(/.{2}/g);
    console.log(datesplit);
    const y = Number(`20${datesplit[0]}`);
    const m = Number(datesplit[1] - 1);
    const d = Number(datesplit[2]);
    const h = Number(datesplit[3]);
    const mm = Number(datesplit[4]);
    const s = Number(datesplit[5]);
    ndate = new Date(y, m, d, h, mm, s);
  } else if (dt.length === 10) {
    let datesplit = dt.match(/.{2}/g);
    console.log(datesplit);
    const y = Number(`20${datesplit[0]}`);
    const m = Number(datesplit[1] - 1);
    const d = Number(datesplit[2]);
    const h = Number(datesplit[3]);
    const mm = Number(datesplit[4]);
    const s = Number(0);
    ndate = new Date(y, m, d, h, mm, s);
  } else {
    ndate = new Date(dt).toUTCString();
  }

  ndate = new Date(ndate);
  const date = `${trailingzero(ndate.getDate())}-${trailingzero(
    ndate.getMonth() + 1
  )}-${ndate.getFullYear()}`;
  const time = formatAMPM(ndate);
  return { date, time };
};

export const trailingzero = (val: any) => {
  return ("0" + val).slice(-2);
};

const formatAMPM = (date: any) => {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  var strTime =
    trailingzero(hours) +
    ":" +
    trailingzero(minutes) +
    // +
    // ":" +
    // trailingzero(date.getSeconds()) +
    // " "
    ampm;
  return strTime;
};

export const thousandK = (
  input: string
): { number: string; aboveThousand: boolean } => {
  const parsedNumber = parseFloat(input);

  if (isNaN(parsedNumber)) {
    throw new Error(
      "Invalid input. Please provide a valid number as a string."
    );
  }

  const formattedNumber =
    parsedNumber > 1000
      ? (parsedNumber / 1000).toFixed(1) + "k"
      : parsedNumber.toString();

  return {
    number: formattedNumber,
    aboveThousand: parsedNumber > 1000,
  };
};

export function doneDateFormattedDateTime(dateTimeString: string) {
  // Split the string into date and time parts
  const year = dateTimeString.slice(0, 2);
  const month = dateTimeString.slice(2, 4);
  const day = dateTimeString.slice(4, 6);
  const hour = dateTimeString.slice(6, 8);
  const minute = dateTimeString.slice(8, 10);

  // Create the formatted date and time string
  const formattedDateTime = `${year}-${month}-${day} ${hour}:${minute}`;

  return formattedDateTime;
}
