//@ts-nocheck

import { useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const RequireAuth = ({ allowedRoles }: { allowedRoles: string[] }) => {
  const { user } = useAuth();
  const location = useLocation();
  const [refreshCheck, setRefreshCheck] = useState<boolean>(false);

  if (Object.keys(user)?.length === 0) {
    return null;
  }

  if (Object.keys(user)?.length !== 0) {
    setTimeout(() => {
      setRefreshCheck(true);
    }, 500);
  }

  return refreshCheck ? (
    user?.isauth ? (
      user.role?.find((role) => allowedRoles?.includes(role)) ? (
        <Outlet />
      ) : (
        <Navigate to="/unauthorized" state={{ from: location }} replace />
      )
    ) : (
      <Navigate to="/" state={{ from: location }} replace />
    )
  ) : (
    <div className="fixed inset-0 z-50 flex flex-col items-center justify-center space-y-3 bg-white p-4">
      <img
        src="/loader.gif"
        alt="logo"
        //style={{ width: 160 }}
      />
      <h4 className="text-lg">Bulk SMS Service</h4>
    </div>
  );
};

export default RequireAuth;
