import request from "./api";

export const LoginApi = async (data: any) => {
  const response = await request.post("/user/login", JSON.stringify({ data }));
  return response.data;
};

export const UserListApi = async () => {
  const response = await request.post("/user", {});
  return response.data;
};

export const RegisterApi = async (data: any) => {
  const response = await request.post("/user/create", JSON.stringify({ data }));
  return response.data;
};

export const CreditSearchApi = async (data: any) => {
  const response = await request.post(
    "/user/creditsearch",
    JSON.stringify({ data })
  );
  return response.data;
};

export const CreateEntityApi = async (data: any) => {
  const response = await request.post(
    "/user/entity/create",
    JSON.stringify({ data })
  );
  return response.data;
};

export const DeleteEntityApi = async (data: any) => {
  const response = await request.post(
    "/user/entity/delete",
    JSON.stringify({ data })
  );
  return response.data;
};

export const CreateTemplateApi = async (data: any) => {
  const response = await request.post(
    "/user/template/create",
    JSON.stringify({ data })
  );
  return response.data;
};

export const DeleteTemplateApi = async (data: any) => {
  const response = await request.post(
    "/user/template/delete",
    JSON.stringify({ data })
  );
  return response.data;
};

export const DeleteScheduledApi = async (data: any) => {
  const response = await request.post(
    "/user/schedule/delete",
    JSON.stringify({ data })
  );
  return response.data;
};
export const ListTemplateApi = async () => {
  const response = await request.post("/user/templates", {});
  return response.data;
};

export const ListEntityApi = async () => {
  const response = await request.post("/user/entities", {});
  return response.data;
};

export const LogoutApi = async () => {
  const response = await request.post("/user/logout", {});
  return response.data;
};

export const AddBalanceApi = async (data: any) => {
  const response = await request.post(
    "/user/balance/add",
    JSON.stringify({ data })
  );
  return response.data;
};

export const ListInvoiceApi = async () => {
  const response = await request.post("/user/invoice", {});
  return response.data;
};

export const ListDetailedReportApi = async (
  day: string,
  from: string,
  to: string,
  scheduled: boolean
) => {
  const response = await request.post("/user/detailedreport", {
    data: { day, from, to, scheduled },
  });
  return response.data;
};

export const ListScheduledReportApi = async (
  day: string,
  from: string,
  to: string
) => {
  const response = await request.post("/user/scheduledreport", {
    data: { day, from, to },
  });
  return response.data;
};

export const ListSummaryReportApi = async (
  day: string,
  from: string,
  to: string
) => {
  const response = await request.post("/user/summaryreport", {
    data: { day, from, to },
  });
  return response.data;
};

export const UserByIdApi = async (id: string, type: string) => {
  const response = await request.post("/user/id", { data: { id, type } });
  return response.data;
};

export const ReportByTrxApi = async (id: string) => {
  const response = await request.post("/user/reportbytrx", { data: { id } });
  return response.data;
};

export const EditUserCpApi = async (data: any) => {
  const response = await request.post("/user/edit/cp", { data });
  return response.data;
};

export const EditUserNmApi = async (data: any) => {
  const response = await request.post("/user/edit/nm", { data });
  return response.data;
};

export const EditUserbyIdApi = async (data: any) => {
  const response = await request.post("/user/edit", { data });
  return response.data;
};

export const BlastSmsApi = async (data: any) => {
  const response = await request.post(
    "/user/quicksms",
    JSON.stringify({ data })
  );
  return response.data;
};

export const UploadSmsApi = async (data: any) => {
  const response = await request.post(
    "/user/uploadsms",
    JSON.stringify({ data })
  );
  return response.data;
};

export const DashApi = async (data: any) => {
  const response = await request.post("/user/dash", JSON.stringify({ data }));
  return response.data;
};

export const DynamicSmsApi = async (data: any) => {
  const response = await request.post(
    "/user/dynamicsms",
    JSON.stringify({ data })
  );
  return response.data;
};

export const ListCampaignApi = async () => {
  const response = await request.post("/user/campaign", {});
  return response.data;
};

export const CreateApiKeyApi = async () => {
  const response = await request.post("/user/api/create", {});
  return response.data;
};
