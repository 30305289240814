import { ApexOptions } from "apexcharts";
import { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import Wrapper from "../../components/Wrapper";
import { defaultUseQueryOption } from "../../helper";
import useAuth from "../../hooks/useAuth";
import { DashApi } from "../../services";

interface ChartOptions {
  series: ApexOptions["series"];
  options: ApexOptions;
}

// Define the data shape
interface Details {
  unicode: string;
  smscount: number;
  isunicode: boolean;
  smslength: number;
  totalnumbers: number;
  smsblastnumber: number;
  duplicatenumber: number;
  removeduplicate: boolean;
}

interface DataItem {
  createdat: string;
  details: Details;
}

const gradientColorsMapping = {
  submitted: ["#FF9999", "#FF4D4D"], // Light to dark red (indicative of starting/initial stages)
  delivered: ["#85E085", "#33CC33"], // Light to dark green (indicative of success/delivered)
  undelivered: ["#9999FF", "#4D4DFF"], // Light to dark blue (indicative of pending or in-transit)
  failed: ["#FFCC66", "#FF9933"], // Light to dark orange (indicative of warnings or failures)
  rejected: ["#FF66B2", "#FF3399"], // Light to dark pink (indicative of rejection or errors)
  other: ["#C2C2F0", "#8585E0"], // Light to dark purple (indicative of other/miscellaneous)
};

const Dashboard = () => {
  const { data: dash, isLoading } = useQuery(
    "dashstat",
    DashApi,
    defaultUseQueryOption()
  );
  //@ts-ignore
  const { user } = useAuth();

  const [pieChart, setPieChart] = useState<ChartOptions>();
  const [barChart, setBarChart] = useState<ChartOptions>();

  const [todaysTotal, setTodaysTotal] = useState<number>(0);
  const [monthlyTotal, setMonthlyTotal] = useState<number>(0);

  useEffect(() => {
    if (!isLoading) {
      const summedData = dash?.data?.charts?.reduce(
        (acc: any, curr: any) => {
          acc.submitted += curr.submitted;
          acc.delivered += curr.delivered;
          acc.undelivered += curr.undelivered;
          acc.failed += curr.failed;
          acc.rejected += curr.rejected;
          acc.other += curr.other;
          return acc;
        },
        {
          submitted: 0,
          delivered: 0,
          undelivered: 0,
          failed: 0,
          rejected: 0,
          other: 0,
        }
      );
      const categories = Object.keys(summedData);
      const categoriesCapitalized = categories.map(
        (category) => category.charAt(0).toUpperCase() + category.slice(1)
      );
      const seriesData: number[] = Object.values(summedData);
      const barGradientColors = categories.map(
        (category) =>
          gradientColorsMapping[category as keyof typeof gradientColorsMapping]
      );

      const series = [...seriesData];
      series.splice(0, 1);
      console.log("seee", series);

      const label = [...categoriesCapitalized];
      label.splice(0, 1);
      console.log("labbee", label);

      const options: ChartOptions = {
        series: [{ name: "Total", data: seriesData as number[] }],
        options: {
          chart: {
            type: "bar" as const,
            height: 350,
            width: "100%",
          },
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 12,
              distributed: true,
              columnWidth: "30%",
              dataLabels: {
                position: "center", // top, center, bottom
              },
            },
          },
          dataLabels: {
            enabled: false,
          },

          xaxis: {
            categories: categoriesCapitalized,
            tickPlacement: "on",
          },
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              type: "vertical",
              shadeIntensity: 0.5,
              gradientToColors: barGradientColors.map((color) => color[1]), // Using the second color from each pair for gradient
              inverseColors: false,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          colors: barGradientColors.map((color) => color[0]),
          legend: {
            show: false,
          },

          tooltip: {
            custom: ({ series, seriesIndex, dataPointIndex, w }: any) => {
              // Get the data you want to display
              const label = categoriesCapitalized[dataPointIndex];
              const data = series[seriesIndex][dataPointIndex];
              // Return custom HTML
              return `
                  <div style="padding: 10px; border: 1px solid #ccc; background: #fff">
                      <h5>${data} ${label}</h5>
                  </div>
              `;
            },
          },
        },
      };

      setBarChart(options);

      const pieChart: ChartOptions = {
        series: series,
        options: {
          labels: label,
          chart: {
            type: "pie" as const,
            width: "100%",
            height: 350,
          },

          colors: ["#85E085", "#9999FF", "#FFCC66", "#FF66B2", "#C2C2F0"],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      };

      setPieChart(pieChart);

      const today = new Date();
      const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

      let localTodaysTotal = 0;
      let localMonthlyTotal = 0;

      dash?.data?.campaign?.forEach((item: DataItem) => {
        const itemDate = new Date(item.createdat);

        if (
          itemDate.getDate() === today.getDate() &&
          itemDate.getMonth() === today.getMonth() &&
          itemDate.getFullYear() === today.getFullYear()
        ) {
          localTodaysTotal += item.details.smsblastnumber;
        }

        if (itemDate >= startOfMonth && itemDate <= today) {
          localMonthlyTotal += item.details.smsblastnumber;
        }
      });

      setTodaysTotal(localTodaysTotal);
      setMonthlyTotal(localMonthlyTotal);
    }

    console.log("user-> ", user);

    
  }, [dash?.data?.campaign, dash?.data?.charts, isLoading]);

  return (
    <Wrapper title="Dashboard" isdashboard>
      <div className="mb-8 grid grid-cols-12 gap-6 ">
        <div className="col-span-12 flex items-center  justify-between  rounded-md bg-blue-200 p-5 md:col-span-8">
          <div className="flex flex-col items-start justify-start space-y-5">
            <div>
              <div className=" text-3xl font-bold text-blue-800">
                Welcome back!
              </div>
              <div className="text-xl font-bold text-blue-800">
                {user?.fullname}
              </div>
            </div>
            <p className="text-md text-blue-600">
              A fresh Quick-Campaign message awaits your attention.
            </p>
            <Link
              to={"/quick-campaign"}
              className="rounded-md bg-blue-700 px-4 py-2 font-semibold text-white shadow-blue-200 hover:bg-blue-800 hover:drop-shadow-lg  "
            >
              Quick Campaign
            </Link>
          </div>
          <div></div>
        </div>
        <div className="col-span-4 hidden items-center justify-between  rounded-md  bg-blue-200 p-5 md:flex">
          <div className="flex flex-col items-start justify-start space-y-5">
            <p className="text-md text-blue-600">
              Get insights from our latest Report Summary.
            </p>
            <Link
              to="/report-summary"
              className="rounded-md bg-blue-700 px-4 py-2 font-semibold text-white shadow-blue-200 hover:bg-blue-800 hover:drop-shadow-lg  "
            >
              Report Summary
            </Link>
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className="flex items-center justify-center p-8 text-lg font-semibold">
          Loading
        </div>
      ) : (
        <>
          <div
            id="stat_block"
            className="grid grid-cols-1 gap-6 md:grid-cols-3"
          >
            <div className="flex items-center justify-between rounded-lg bg-[#fff1d7] p-2 shadow md:p-4 ">
              <div className="flex flex-col space-y-1">
                <div className="text-md font-semibold text-gray-600">
                  SMS count today
                </div>
                <div className="mb-4 text-2xl font-bold">{todaysTotal}</div>
              </div>
              <span className="flex items-end space-x-1">
                <span className="h-5 w-2 rounded-sm bg-black"></span>
                <span className="h-9 w-2 rounded-sm bg-[#ffbe5b]"></span>
                <span className="h-4 w-2 rounded-sm bg-black"></span>
                <span className="h-5 w-2 rounded-sm bg-[#ffbe5b]"></span>
                <span className="h-4 w-2 rounded-sm bg-black"></span>
                <span className="h-10 w-2 rounded-sm bg-[#ffbe5b]"></span>
                <span className="h-3 w-2 rounded-sm bg-black"></span>
                <span className="h-5 w-2 rounded-sm bg-[#ffbe5b]"></span>
                <span className="h-4 w-2 rounded-sm bg-black"></span>
                <span className="h-12 w-2 rounded-sm bg-[#ffbe5b]"></span>
              </span>
            </div>
            <div className="flex  items-center justify-between rounded-lg bg-[#ffeee7] p-2 shadow md:p-4 ">
              <div className="flex flex-col space-y-1">
                <div className="text-md font-semibold text-gray-600">
                  SMS count current month
                </div>
                <div className="mb-4 text-2xl font-bold">{monthlyTotal}</div>
              </div>
              <span className="flex items-end space-x-1">
                <span className="h-5 w-2 rounded-sm bg-black"></span>
                <span className="h-9 w-2 rounded-sm bg-[#ff747c]"></span>
                <span className="h-4 w-2 rounded-sm bg-black"></span>
                <span className="h-5 w-2 rounded-sm bg-[#ff747c]"></span>
                <span className="h-4 w-2 rounded-sm bg-black"></span>
                <span className="h-10 w-2 rounded-sm bg-[#ff747c]"></span>
                <span className="h-3 w-2 rounded-sm bg-black"></span>
                <span className="h-5 w-2 rounded-sm bg-[#ff747c]"></span>
                <span className="h-4 w-2 rounded-sm bg-black"></span>
                <span className="h-12 w-2 rounded-sm bg-[#ff747c]"></span>
              </span>
            </div>
            <div className="flex items-center justify-between rounded-lg bg-[#f0fcec] p-2 shadow md:p-4 ">
              <div className="flex flex-col space-y-1">
                <div className="text-md font-semibold text-gray-600">
                  Available credit
                </div>
                <div className="mb-4 text-2xl font-bold">
                  {user?.role[0] === "client" ? (
                    <div>{dash?.data?.balance?.actual}</div>
                  ) : (
                    <>
                      <div>ac:{dash?.data?.balance?.actual}</div>
                      <div>av:{dash?.data?.balance?.available}</div>
                      <div>vi:{dash?.data?.balance?.virtual}</div>
                    </>
                  )}
                </div>
              </div>
              <span className="flex items-end space-x-3">
                <div className="donut rounded-full border-8 border-green-400"></div>
              </span>
            </div>
          </div>
          <div className="mt-8 grid grid-cols-1 gap-6 md:grid-cols-3">
            <div className="col-span-2 rounded-lg bg-white p-3 shadow">
              <h3 className="mb-4 text-lg font-semibold text-gray-600">
                Today's summary report
              </h3>
              <ApexCharts
                options={barChart?.options || {}}
                series={barChart?.series || []}
                type="bar"
                height={350}
              />
            </div>
            <div className="rounded-lg bg-white p-3 shadow">
              <h3 className="mb-8 text-lg font-semibold text-gray-600">
                Today's summary report
              </h3>
              <ApexCharts
                options={pieChart?.options || {}}
                series={pieChart?.series || []}
                type="pie"
              />
            </div>
          </div>
        </>
      )}
    </Wrapper>
  );
};

export default Dashboard;
