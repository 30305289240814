import { TextInput } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { IUserInput, userSchema } from "../schema";

const SettingUserProfile = ({ user }: { user: IUserInput }) => {
  // const { mutate, isLoading } = useMutation(RegisterApi, {
  //   onSuccess: (data) => {
  //     form.reset();
  //     showNotification({
  //       autoClose: 5000,
  //       title: "Create user",
  //       message: "User Add successfully done",
  //       color: "green"
  //     })
  //   },
  //   onError: () => {
  //     showNotification({
  //       autoClose: 5000,
  //       title: "Create user",
  //       message: "Something went wrong, please try again",
  //       color: "red"
  //     })
  //   }
  // });

  const form = useForm<IUserInput>({
    validate: zodResolver(userSchema),
    initialValues: {
      username: user?.username,
      fullname: user?.fullname,
      role: user?.role,
      password: "",
      phone: user?.phone,
      email: user?.email,

      misc: {
        usertype: user?.misc?.usertype,
      },
      reseller: {
        domain: user?.reseller?.domain,
      },
      canaddvirtualcredit: user?.canaddvirtualcredit,
      viewcredithistory: user?.canaddvirtualcredit,
      status: user?.status,
    },
  });

  const onAddUser = (values: IUserInput) => {
    const data = {
      ...values,
    };

    if (data.role === "client") {
      delete data.reseller;
    }

    //   mutate(data);
  };

  return (
    <div className=" flex flex-col space-y-5 p-5">
      <div className="flex flex-row flex-wrap items-center">
        <div className="basis-full md:basis-1/3">Username</div>
        <div className="basis-full md:basis-1/2">
          <TextInput
            placeholder="Full Name"
            readOnly
            size="md"
            variant="filled"
            {...form.getInputProps("username")}
          />
        </div>
      </div>

      <div className="flex flex-row flex-wrap items-center">
        <div className="basis-full md:basis-1/3">Full Name</div>
        <div className="basis-full md:basis-1/2">
          <TextInput
            placeholder="Full Name"
            readOnly
            size="md"
            variant="filled"
            {...form.getInputProps("fullname")}
          />
        </div>
      </div>

      <div className="flex flex-row flex-wrap items-center">
        <div className="basis-full md:basis-1/3">Mobile Number</div>
        <div className="basis-full md:basis-1/2">
          <TextInput
            placeholder="Mobile Number"
            readOnly
            size="md"
            variant="filled"
            {...form.getInputProps("phone")}
            value={form.values.phone}
          />
        </div>
      </div>

      <div className="flex flex-row flex-wrap items-center">
        <div className="basis-full md:basis-1/3">Email</div>
        <div className="basis-full md:basis-1/2">
          <TextInput
            placeholder="Email"
            readOnly
            size="md"
            variant="filled"
            {...form.getInputProps("email")}
            value={form.values.email}
          />
        </div>
      </div>
    </div>
  );
};

export default SettingUserProfile;
