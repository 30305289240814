import { Button, CopyButton, TextInput } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useEffect, useState } from "react";
import { RiKey2Fill } from "react-icons/ri";
import { useMutation } from "react-query";
import { CreateApiKeyApi } from "../services";
import ApiDocumentation from "./ApiDocumentation";

const UserApiKey = ({ apikey }: { apikey: string }) => {
  const [apiKey, setApiKey] = useState("");

  useEffect(() => {
    setApiKey(apikey || "");
  }, [apikey]);

  const { mutate, isLoading } = useMutation(CreateApiKeyApi, {
    onSuccess: (data) => {
      setApiKey(data?.data);
      showNotification({
        autoClose: 5000,
        title: "API KEY",
        message: "API key generated",
        color: "green",
      });
    },
    onError: () => {
      showNotification({
        autoClose: 5000,
        title: "API KEY",
        message: "Something went wrong, please try again",
        color: "red",
      });
    },
  });

  return (
    <div className=" flex flex-col space-y-5 p-5">
      <div className="flex flex-row flex-wrap items-center">
        <div className="basis-full md:basis-1/4">Api Key</div>
        <div className="basis-full">
          <div className="flex w-full">
            <TextInput
              className="flex-1"
              placeholder="Key"
              readOnly
              size="sm"
              value={apiKey}
            />
            {apiKey !== "" && (
              <CopyButton value={apiKey}>
                {({ copied, copy }) => (
                  <Button
                    className="ml-2 bg-gradient-to-br from-[#4d88c4] to-[#589bd6] px-2  hover:bg-gradient-to-r hover:from-[#589bd6] hover:to-[#4d88c4]"
                    color={copied ? "teal" : "blue"}
                    onClick={copy}
                  >
                    {copied ? "Copied" : "Copy"}
                  </Button>
                )}
              </CopyButton>
            )}
            <Button
              size="sm"
              variant="filled"
              className="ml-2 bg-gradient-to-br from-[#4d88c4] to-[#589bd6] px-2  hover:bg-gradient-to-r hover:from-[#589bd6] hover:to-[#4d88c4]"
              type="button"
              onClick={() => mutate()}
              loading={isLoading}
            >
              <span className="ml-2">
                <RiKey2Fill />
              </span>
            </Button>
          </div>
        </div>
      </div>
      {apiKey !== "" && <ApiDocumentation apikey={apiKey} />}
    </div>
  );
};

export default UserApiKey;
