//@ts-nocheck
import { showNotification } from "@mantine/notifications";

import {
  Box,
  Button,
  Modal,
  Select,
  Space,
  TextInput,
  Textarea,
  Tooltip
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useState } from "react";
//@ts-ignore
import GSM from "gsm";
import _ from "lodash";
import { useQuery } from "react-query";
import Wrapper from "../../components/Wrapper";
import { defaultUseQueryOption, getTimeInHourMinuteAMPM, validNumber } from "../../helper";
import useAuth from "../../hooks/useAuth";
import { BlastSmsApi, ListEntityApi, ListTemplateApi } from "../../services";


import '../../styles/Iphone.css'; // Create a separate CSS file for styling


const QuickCampaign = () => {
  const [numbers, setNumbers] = useState<string>();
  const [isunicode, setIsUnicode] = useState(false);
  const [smslen, setSmslen] = useState("0/160, 0");
  const [numberCount, setNumberCount] = useState<string>("0 Number(s)");
  const [loading,setLoading] = useState(false);
  const [opened, setOpened] = useState(false);

  const { user } = useAuth();

  const { data: templates } = useQuery(
    "templates",
    ListTemplateApi,
    defaultUseQueryOption()
  );

  const { data: entities } = useQuery(
    "entities",
    ListEntityApi,
    defaultUseQueryOption()
  );

  const form = useForm({
    initialValues: {
      title: `${user?.username}-campaign-${Date.now()}`,
      entityid: "",
      entity: "",
      template: "",
      sms: "",
      sender: "",
    },
  });

  const onChangeNumber = (value: any) => {
    const numbers = value;
    const nm_list = _.split(numbers, "\n", 10000);
    setNumbers(nm_list.join("\n"));

    const valid_contacts = _.filter(_.split(numbers, "\n"), (o) =>
      validNumber(o)
    );
    const noduplicate = _.uniq(valid_contacts).length;

    setNumberCount(
      `${nm_list.length} Numbers |${valid_contacts.length} Valid, ${valid_contacts.length - noduplicate
      } Dupicate`
    );
  };

  const onClickSetMessage = (val: string) => {
    const e_value = val;
    const sms_calc = GSM(e_value);
    if (sms_calc.char_set !== "Unicode") {
      setIsUnicode(false);
      if (sms_calc.sms_count > 1) {
        setSmslen(
          `${sms_calc.sms_count * 153 - sms_calc.chars_left}/${sms_calc.sms_count * 153
          }, ${sms_calc.sms_count}`
        );
      } else {
        setSmslen(`${160 - sms_calc.chars_left}/160, ${sms_calc.sms_count}`);
      }
    } else {
      setIsUnicode(true);
      if (sms_calc.sms_count > 1) {
        setSmslen(
          `${sms_calc.sms_count * 67 - sms_calc.chars_left}/${sms_calc.sms_count * 67
          }, ${sms_calc.sms_count}`
        );
      } else {
        setSmslen(`${70 - sms_calc.chars_left}/70, ${sms_calc.sms_count}`);
      }
    }
  };

  const BlastSms = async () => {
    const valid_contacts = _.filter(_.split(numbers, "\n"), (o) =>
      validNumber(o)
    );
    const noduplicate = _.uniq(valid_contacts).length;
    const removeDuplicate = true;

    const req_data = {
      title: form.values.title,
      templateid: form.values.template,
      entityid: form.values.entity,
      sender: form.values.sender,
      sms: form.values.sms,
      details: {
        isunicode,
        unicode: isunicode ? "unicode" : "english",
        smslength: Number(smslen.split("/")[0]),
        smscount: Number(smslen.split("/")[1].split(",")[1]),
        removeduplicate: removeDuplicate,
        totalnumbers: valid_contacts.length,
        duplicatenumber: valid_contacts.length - noduplicate,
        smsblastnumber: removeDuplicate
          ? valid_contacts.length - (valid_contacts.length - noduplicate)
          : valid_contacts.length,
      },

      contactlist: removeDuplicate ? _.uniq(valid_contacts) : valid_contacts,
    };

    console.log("sms data --> ", req_data);


    setLoading(true)


    await BlastSmsApi({ ...req_data })
      .then((a) => {
        // resetting form
       if (a?.data?.success) {
         form.reset()
         setNumbers("")
         setIsUnicode(false);
         setSmslen("0/160, 0");
         setNumberCount("0 Number(s)");
         setLoading(false)
         setOpened(false)

         showNotification({
           autoClose: 5000,
           title: "Quick Message Success",
           message: a?.data?.message,
           color: "green"
         });
       }else{
         showNotification({
           autoClose: 5000,
           title: "Quick Message Error",
           message: a?.data?.message,
           color: "red"
         });
         setLoading(false)
         setOpened(false)
       }
      })
      .catch((e) => {
        console.log(e);
       
        showNotification({
          autoClose: 5000,
          title: "Quick Message Error",
          message: e?.message,
          color: "green"
        });
        setLoading(false)
        setOpened(false)
      });
  };

  const [reQ, setReQ] = useState({
    unicode: "",
    smslength: "",
    smscount: "",
    removeduplicate: "",
    totalnumbers: "",
    duplicatenumber: "",
    smsblastnumber: "",
    sender: "",
    sms: ""
  });

  const ConfirmRequest = async () => {
    const valid_contacts = _.filter(_.split(numbers, "\n"), (o) =>
      validNumber(o)
    );
    const noduplicate = _.uniq(valid_contacts).length;
    const removeDuplicate = true;

    const req_data = {
      sender: form.values.sender,
      sms: form.values.sms,
      templateid: form.values.template,
      entityid: form.values.entity,
      isunicode,
      unicode: isunicode ? "unicode" : "english",
      smslength: Number(smslen.split("/")[0]),
      smscount: Number(smslen.split("/")[1].split(",")[1]),
      removeduplicate: removeDuplicate,
      totalnumbers: valid_contacts.length,
      duplicatenumber: valid_contacts.length - noduplicate,
      smsblastnumber: removeDuplicate
        ? valid_contacts.length - (valid_contacts.length - noduplicate)
        : valid_contacts.length,
    };


    if (req_data.totalnumbers <= 0) {
      showNotification({
        autoClose: 5000,
        title: "Input Error",
        message: "No valid numbers provided",
        color: "red"
      });
      return;
    }

    if (!req_data.sender) {
      showNotification({
        autoClose: 5000,
        title: "Input Error",
        message: "Select sender ID",
        color: "red"
      });
      return;
    }

    if (!req_data.templateid) {
      showNotification({
        autoClose: 5000,
        title: "Input Error",
        message: "Select template name",
        color: "red"
      });
      return;
    }

    if (!req_data.entityid) {
      showNotification({
        autoClose: 5000,
        title: "Input Error",
        message: "Select sender ID or entity ID",
        color: "red"
      });
      return;
    }

    if (!req_data.sms) {
      showNotification({
        autoClose: 5000,
        title: "Input Error",
        message: "Message text cannot be empty",
        color: "red"
      });
      return;
    }


    setReQ(req_data);
    setOpened(!opened)

  };

  return (
    <Wrapper header="Quick Campaign" title="SMS Campaign,Quick-Campaign">

      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title="Confirm Request"
        size={"lg"}
      >
        <table id="mod_table" className="table w-full">
          <tbody>
            <tr>
              <td>Sender</td>
              <td>{reQ.sender}</td>
            </tr>
            <tr>
              <td>Message</td>
              <td>{reQ.sms}</td>
            </tr>
            <tr>
              <td>Character Count</td>
              <td>{reQ.smslength} characters</td>
            </tr>
            <tr>
              <td>SMS Credit</td>
              <td>{reQ.smscount} SMS Credits(s)</td>
            </tr>
            <tr>
              <td>Valid Numbers</td>
              <td>{reQ?.totalnumbers} numbers(s)</td>
            </tr>
          </tbody>
        </table>
        <div className="flex justify-end gap-x-4 mt-3" >
          <Button
            size="md"
            variant="filled"
            className="bg-gradient-to-br from-[#4d88c4] to-[#589bd6] px-7 py-3 hover:bg-gradient-to-r hover:from-[#589bd6] hover:to-[#4d88c4]"
            onClick={BlastSms}
            disabled={loading}
          >
            {loading ? "submitting..." : "Submit"}
          </Button>
          <Button onClick={() => setOpened(!opened)} size="md" variant="outline" color={"#4d88c4"}>
            Close
          </Button>
        </div>
      </Modal>

      <div className="chat-app-container">
        <div className="input-container">
          <Box p={20} className="rounded-lg bg-white shadow-2xl">
            <Box className="rounded-lg bg-[#e8eff7] ">
              <div className=" flex flex-col space-y-5 p-5">
                <div className="flex flex-row flex-wrap items-center">
                  <div className="basis-full md:basis-1/4">Campaign Name</div>
                  <div className="basis-full md:basis-3/4">
                    <TextInput
                      placeholder="Campaign Name"
                      size="md"
                      {...form.getInputProps("title")}
                    />
                  </div>
                </div>

                <div className="flex flex-row flex-wrap items-center">
                  <div className="basis-full md:basis-1/4">Mobile Number(s)</div>
                  <div className="basis-full md:basis-3/4">
                    <Textarea
                      placeholder="Mobile Number(s)"
                      size="md"
                      minRows={4}
                      maxRows={4}
                      spellCheck={false}
                      value={numbers}
                      onChange={(event) =>
                        onChangeNumber(event.currentTarget.value)
                      }
                    />
                    <div className="flex justify-between">
                      <span className="p-1 font-mono text-sm text-gray-400">
                        Note: Enter mobile numbers (new-line seprated)
                      </span>
                      <span className="p-1 font-mono text-sm text-gray-400">
                        <Tooltip
                          color="blue"
                          withArrow
                          label={numberCount.split("|")[1]}
                        >
                          <span
                            className={`${numberCount.split("|").length > 1
                              ? "cursor-pointer underline"
                              : ""
                              }`}
                          >
                            {numberCount.split("|")[0]}
                          </span>
                        </Tooltip>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="flex flex-row flex-wrap items-center">
                  <div className="basis-full md:basis-1/4">Sender Id</div>
                  <div className="basis-full md:basis-3/4">
                    <Select

                      nothingFound="No sender id found"
                      placeholder="--select senderid--"
                      data={
                        entities?.data?.map((a: any) => {
                          return { value: a?.id, label: a?.sender };
                        }) || []
                      }
                      value={form.values.entityid}
                      onChange={(value: any) => {
                        console.log(value);
                        const entity = entities?.data?.find((a: any) => {
                          return a.id === Number(value);
                        });
                        form.setFieldValue("entityid", value);
                        form.setFieldValue("entity", entity?.entity);
                        form.setFieldValue("sender", entity?.sender);
                        form.setFieldValue("sms", "");
                        form.setFieldValue("template", "");
                        onClickSetMessage("");
                      }}
                    />
                  </div>
                </div>

                <div className="flex flex-row flex-wrap items-center">
                  <div className="basis-full md:basis-1/4">Template Name</div>
                  <div className="basis-full md:basis-3/4">
                    <Select

                      placeholder="--select template--"
                      nothingFound="No template found"
                      data={
                        templates?.data
                          ?.filter((a: any) => a.entityid === form.values.entityid)
                          ?.map((b: any) => {
                            return { value: b?.operatortempid, label: b?.title };
                          }) || []
                      }
                      value={form.values.template}
                      onChange={(value: any) => {
                        console.log("id", value);
                        const sms = templates?.data?.find((a: any) => {
                          return a.operatortempid === value;
                        });
                        form.setFieldValue("template", value);

                        console.log("sms", sms);

                        form.setFieldValue("sms", sms?.template);
                        onClickSetMessage(sms?.template);
                      }}
                    />
                  </div>
                </div>

                <div className="flex flex-row flex-wrap items-center">
                  <div className="basis-full md:basis-1/4">Message Text</div>
                  <div className="basis-full md:basis-3/4 ">
                    <Textarea
                      size="md"
                      placeholder="Message Text"
                      minRows={4}
                      maxRows={4}
                      spellCheck={false}
                      value={form.values.sms}
                      onChange={(event: any) => {
                        console.log(event);
                        form.setFieldValue("sms", event.currentTarget.value);
                        onClickSetMessage(event.currentTarget.value);
                      }}
                    />
                    <span className="flex justify-end p-1 font-mono  text-sm text-gray-500">
                      {isunicode ? "unicode" : "english"} {smslen} SMS
                    </span>
                  </div>
                </div>

                <div>
                  <Space h={"md"} />
                  <div className="flex flex-row flex-wrap items-center">
                    <div className="basis-full md:basis-1/4"></div>
                    <div className="basis-full space-x-3 md:basis-3/4 ">
                      <Button
                        size="md"
                        variant="filled"
                        className="bg-gradient-to-br from-[#4d88c4] to-[#589bd6] px-7 py-3 hover:bg-gradient-to-r hover:from-[#589bd6] hover:to-[#4d88c4]"
                        onClick={ConfirmRequest}
                      >
                        Submit
                      </Button>
                      <Button onClick={() => {
                        form.reset()
                        setNumbers("")
                        setIsUnicode(false);
                        setSmslen("0/160, 0");
                        setNumberCount("0 Number(s)");
                      }} size="md" variant="outline" color={"#4d88c4"}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </Box>
        </div>
        <div className="iphone-container shrink">
          <div className="iphone-frame">
            <div className="notch"></div>
            <div className="flex justify-center items-end pb-2 bg-gray-200 h-[60px] text-sm"><span>{form.values.sender.length > 0 ? form.values.sender : "New Message"}</span></div>
            <div className="iphone-screen flex-col justify-start items-center py-3 gap-y-2">
              <div className="flex flex-col justify-center items-center text-xs text-gray-400" >
                <div>Quick Message</div>
                <div>Today {getTimeInHourMinuteAMPM()}</div>
              </div>
              {
                form.values.sms.length > 0 ? <div className="imessage self-end text-sm">{form.values.sms}</div> : null
              }
            </div>
          </div>
        </div>
      </div>


    </Wrapper>
  );
};

export default QuickCampaign;

//Message submitted to platform. Total Number Uploaded :- 5 .Duplicate/Incorrect Number Count :- 4 .Number submitted to platform:- 1
