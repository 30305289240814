function APIDocumentation({ apikey }: { apikey: string }) {
  return (
    <div>
      <h1 className="font-semibold">API Documentation</h1>
      <p>
        This API documentation component provides information about the SMS API.
      </p>

      <h2>Endpoint</h2>
      <code>https://smskeys.in/api/v1/sms</code>

      <h2>Parameters</h2>
      <table className="table">
        <thead>
          <tr>
            <th>Parameter</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>number</td>
            <td>The recipient's phone number (e.g., 0000000000).</td>
          </tr>
          <tr>
            <td>sender</td>
            <td>The sender's name or identifier (e.g., SENDER).</td>
          </tr>
          <tr>
            <td>templateid</td>
            <td>The template ID (e.g., 00000000000000).</td>
          </tr>
          <tr>
            <td>peid</td>
            <td>The peid (e.g., 0000000000000).</td>
          </tr>
          <tr>
            <td>message</td>
            <td>
              The message to be sent. Make sure to encode special characters
              (e.g., Your message goes here).
            </td>
          </tr>
          <tr>
            <td>key</td>
            <td>
              The API key for authentication and security (e.g.,{apikey}).
            </td>
          </tr>
        </tbody>
      </table>

      <div className="p-2">
        <h2 className="my-2">Example (JavaScript)</h2>
        <pre
          style={{ background: "black", color: "white" }}
          className="block w-full overflow-hidden whitespace-pre-wrap break-words p-2"
        >
          {`const apiUrl = https://smskeys.in/api/v1/sms?number=0000000000&sender=SENDER&templateid=0000000000&peid=0000000000&message=your message goes hear&key=${apikey};
        fetch(apiUrl)
          .then(response => response.json())
          .then(data => {
            console.log(data);
          })
          .catch(error => {
            console.error(error);
          });`}
        </pre>

        <h2 className="my-2">Example (PHP)</h2>
        <pre
          className="block w-full overflow-hidden whitespace-pre-wrap break-words p-2"
          style={{ background: "black", color: "white" }}
        >
          {` <?php
  $url = 'https://smskeys.in/api/v1/sms';
  $params = array(
      'number' => '0000000000',
      'sender' => 'SENDER',
      'templateid' => '00000000',
      'peid' => '000000000',
      'message' => 'your message goes hear',
      'key' => ${apikey}
  );

  $ch = curl_init($url);
  curl_setopt($ch, CURLOPT_RETURNTRANSFER, 1);
  curl_setopt($ch, CURLOPT_POSTFIELDS, http_build_query($params));
  $response = curl_exec($ch);
  curl_close($ch);

  echo $response;
  ?>`}
        </pre>
      </div>
    </div>
  );
}

export default APIDocumentation;
