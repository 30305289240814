//@ts-nocheck

import {
  Box,
  Button,
  Center,
  FileInput,
  Radio,
  RingProgress,
  Select,
  Space,
  Text,
  TextInput,
  Textarea,
  ThemeIcon,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { useRef, useState } from "react";
import { TbPlus, TbUpload } from "react-icons/tb";
import { useQuery } from "react-query";
import Wrapper from "../../components/Wrapper";
import { defaultUseQueryOption } from "../../helper";
import useAuth from "../../hooks/useAuth";
import { DynamicSmsApi, ListEntityApi, ListTemplateApi } from "../../services";
import request from "../../services/api";
//@ts-ignore
import GSM from "gsm";
import { FaCheck, FaExclamationTriangle } from "react-icons/fa";
const UploadCampaign = () => {
  const [isunicode, setIsUnicode] = useState(false);
  const [smslen, setSmslen] = useState("0/160, 0");
  const [fileUpload, setFileUpload] = useState({
    message: "",
    valid: 0,
    filepath: "",
    duplicate: 0,
    invalid: 0,
    headers: [],
    jsonfilepath: "",
  });
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [fileStatus, setFileStatus] = useState({
    error: false,
    success: false,
    isDirty: false,
    message: "",
  });

  const { user } = useAuth();

  const { data: templates } = useQuery(
    "templates",
    ListTemplateApi,
    defaultUseQueryOption()
  );
  const { data: entities } = useQuery(
    "entities",
    ListEntityApi,
    defaultUseQueryOption()
  );

  const form = useForm({
    initialValues: {
      title: `${user?.username}-campaign-${Date.now()}`,
      entityid: "",
      entity: "",
      template: "",
      sms: "",
      sender: "",
      isScheduled: "no",
      isSplit: "no",
      scheduleAt: new Date(),
      numberkey: "",
      columnkey: "",
      splitdetails: [
        {
          form: "",
          to: "",
          time: "",
        },
      ],
    },
  });

  const handleFileChange = async (file: File) => {
    const formData = new FormData();
    formData.append("newFile", file);
    try {
      setFileStatus((prev) => ({
        ...prev,
        isDirty: true,
        error: false,
        success: false,
      }));
      const response = await request.post("/user/dynamicupload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadPercentage(percentCompleted);
        },
      });

      setUploadPercentage(100);
      setFileStatus((prev) => ({
        ...prev,
        success: true,
        error: false,
      }));

      console.log("Uploaded! :: ", response?.data);

      if (response?.data?.success) {
        setFileStatus((prev) => ({
          ...prev,
          success: true,
          error: false,
          message: response?.data?.data?.message,
        }));

        showNotification({
          title: "File uploaded successfully.",
          message: response?.data?.data?.message,
          autoClose: 5000,
          color: "green",
        });

        setFileUpload(response?.data?.data);

        //response?.data?.data?.filepath
      } else {
        setFileStatus((prev) => ({
          ...prev,
          success: false,
          error: true,
          message: response?.data?.data?.message,
        }));

        showNotification({
          title: "File upload error.",
          message: response?.data?.data?.message,
          autoClose: 5000,
          color: "red",
        });

        setFileUpload((prev) => ({
          ...prev,
          valid: 0,
        }));
      }
    } catch (error) {
      setFileUpload((prev) => ({
        ...prev,
        valid: 0,
      }));
      showNotification({
        title: "File type error.",
        message: error.message,
        autoClose: 5000,
        color: "red",
      });
      // setUploadPercentage(0);
      setFileStatus((prev) => ({
        ...prev,
        success: false,
        error: true,
      }));
    }
  };

  const onClickSetMessage = (val: string) => {
    const e_value = val;
    const sms_calc = GSM(e_value);
    if (sms_calc.char_set !== "Unicode") {
      setIsUnicode(false);
      if (sms_calc.sms_count > 1) {
        setSmslen(
          `${sms_calc.sms_count * 153 - sms_calc.chars_left}/${
            sms_calc.sms_count * 153
          }, ${sms_calc.sms_count}`
        );
      } else {
        setSmslen(`${160 - sms_calc.chars_left}/160, ${sms_calc.sms_count}`);
      }
    } else {
      setIsUnicode(true);
      if (sms_calc.sms_count > 1) {
        setSmslen(
          `${sms_calc.sms_count * 67 - sms_calc.chars_left}/${
            sms_calc.sms_count * 67
          }, ${sms_calc.sms_count}`
        );
      } else {
        setSmslen(`${70 - sms_calc.chars_left}/70, ${sms_calc.sms_count}`);
      }
    }
  };

  const BlastSms = async () => {
    //WIP
    if (form.values.isScheduled === "yes") return alert("Work in progress!!");

    const req_data = {
      title: form.values.title,
      templateid: form.values.template,
      entityid: form.values.entity,
      sender: form.values.sender,
      sms: form.values.sms,
      details: {
        isunicode,
        unicode: isunicode ? "unicode" : "english",
        smslength: Number(smslen.split("/")[0]),
        smscount: Number(smslen.split("/")[1].split(",")[1]),
        removeduplicate: true,
        totalnumbers:
          fileUpload.duplicate + fileUpload.invalid + fileUpload.valid,
        duplicatenumber: fileUpload.duplicate,
        smsblastnumber: fileUpload.valid,
      },
      filepath: {
        uploaded: fileUpload?.filepath,
        json: fileUpload?.jsonfilepath,
      },
      contactlist: null,
      isscheduled: form.values.isScheduled === "yes" ? true : false,
      issplit: form.values.isSplit === "yes" ? true : false,

      dynamicdetails: {
        numberkey: form.values.numberkey,
        headers: fileUpload.headers,
      },

      scheduledat:
        form.values.isScheduled === "yes"
          ? new Date(form.values.scheduleAt)
          : null,
      splitdetails:
        form.values.isSplit === "yes"
          ? form.values.splitdetails.map((a) => {
              return {
                from: a.form,
                to: a.to,
                scheduledat: new Date(`${form.values.scheduleAt} ${a.time}`),
              };
            })
          : null,
    };

    console.log(req_data);
    await DynamicSmsApi({ ...req_data })
      .then((a) => {
        // resetting form
        if (a.success) {
          form.reset();
          setFileStatus({
            error: false,
            success: false,
            isDirty: false,
            message: "",
          });
          setFileUpload({
            message: "",
            valid: 0,
            filepath: "",
            duplicate: 0,
            invalid: 0,
            headers: [],
            jsonfilepath: "",
          });
          setIsUnicode(false);
          setSmslen("0 / 160, 0");
          setUploadPercentage(0);
        } else {
          alert(a?.data?.message);
        }
      })
      .catch((e) => {
        console.log("e::", e);
        alert(e?.message);
      });
  };

  const templateRef = useRef<HTMLTextAreaElement>(null);

  const insertVariable = () => {
    const selectionStart = templateRef.current?.selectionStart;
    const selectionEnd = templateRef.current?.selectionEnd;

    const value = form.values.sms;

    let newValue =
      value.substring(0, selectionStart) +
      `{#${form.values.columnkey}#}` +
      value.substring(selectionEnd || 0, value.length);

    form.setFieldValue("sms", newValue);

    onClickSetMessage(newValue);
  };

  return (
    <Wrapper header="Dynamic Campaign" title="SMS Campaign,Dynamic-Campaign">
      <Box p={20} className="rounded-lg bg-white shadow-2xl">
        <Box className="rounded-lg bg-[#e8eff7] ">
          <div className=" flex flex-col space-y-5 p-5">
            <div className="flex flex-row flex-wrap items-center">
              <div className="basis-full md:basis-1/3">Campaign Name</div>
              <div className="basis-full md:basis-1/2">
                <TextInput
                  color="indigo"
                  placeholder="Campaign Name"
                  size="md"
                  {...form.getInputProps("title")}
                />
              </div>
            </div>

            <div className="flex flex-row flex-wrap items-start">
              <div className="basis-full md:basis-1/3">Select File</div>
              <div className="basis-full md:basis-1/2">
                <FileInput
                  placeholder="Choose file"
                  icon={
                    fileStatus.isDirty ? (
                      <>
                        <RingProgress
                          size={38}
                          thickness={3}
                          sections={[
                            {
                              value: uploadPercentage,
                              color: fileStatus.error ? "red" : "teal",
                            },
                          ]}
                          label={
                            <Center>
                              {fileStatus.error ? (
                                <ThemeIcon color="red" radius="xl" size={20}>
                                  <Center>
                                    <FaExclamationTriangle size={8} />
                                  </Center>
                                </ThemeIcon>
                              ) : fileStatus.success ? (
                                <ThemeIcon color="teal" radius="xl" size={20}>
                                  <Center>
                                    <FaCheck size={8} />
                                  </Center>
                                </ThemeIcon>
                              ) : (
                                <Text size={9}>{uploadPercentage}%</Text>
                              )}
                            </Center>
                          }
                        />
                      </>
                    ) : (
                      <TbUpload />
                    )
                  }
                  size="md"
                  onChange={handleFileChange}
                />

                {fileUpload.valid !== "0" && fileUpload.valid !== 0 && (
                  <div className="flex justify-end">
                    <span className="p-1 font-mono text-sm text-gray-400">
                      {fileUpload.valid} Numbers(s)
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-row flex-wrap items-center">
              <div className="basis-full md:basis-1/3">Sender Id</div>
              <div className="basis-full md:basis-1/2">
                <Select
                  searchable
                  clearable
                  nothingFound="No sender id found"
                  placeholder="--select senderid--"
                  data={
                    entities?.data?.map((a: any) => {
                      return { value: a?.id, label: a?.sender };
                    }) || []
                  }
                  value={form.values.entityid}
                  onChange={(value: any) => {
                    console.log(value);
                    const entity = entities?.data?.find((a: any) => {
                      return a.id === Number(value);
                    });
                    form.setFieldValue("entityid", value);
                    form.setFieldValue("entity", entity?.entity);
                    form.setFieldValue("sender", entity?.sender);
                    form.setFieldValue("sms", "");
                    form.setFieldValue("template", "");
                    onClickSetMessage("");
                  }}
                />
              </div>
            </div>

            <div className="flex flex-row flex-wrap items-center">
              <div className="basis-full md:basis-1/3">Template Name</div>
              <div className="basis-full md:basis-1/2">
                <Select
                  clearable
                  searchable
                  placeholder="--select template--"
                  nothingFound="No template found"
                  data={
                    templates?.data
                      ?.filter((a: any) => a.entityid === form.values.entityid)
                      ?.map((b: any) => {
                        return { value: b?.operatortempid, label: b?.title };
                      }) || []
                  }
                  value={form.values.template}
                  onChange={(value: any) => {
                    console.log(value);
                    const sms = templates?.data?.find((a: any) => {
                      return a.operatortempid === value;
                    })?.template;
                    form.setFieldValue("template", value);
                    form.setFieldValue("sms", sms);
                    onClickSetMessage(sms);
                  }}
                />
              </div>
            </div>

            <div className="flex flex-row flex-wrap items-center">
              <div className="basis-full md:basis-1/3">Mobile Number</div>
              <div className="basis-full md:basis-1/2">
                <Select
                  data={fileUpload.headers.map((a) => a.toString()) || []}
                  placeholder="-- select mobile number --"
                  size="md"
                  {...form.getInputProps("numberkey")}
                />
              </div>
            </div>

            <div className="flex flex-row flex-wrap items-center">
              <div className="basis-full md:basis-1/3">Column List</div>
              <div className="basis-full md:basis-1/2">
                <Select
                  data={fileUpload.headers.map((a) => a.toString()) || []}
                  placeholder="-- select column to add --"
                  size="md"
                  {...form.getInputProps("columnkey")}
                />
              </div>
              <div className="ml-3 basis-full md:basis-1/12">
                <Button
                  radius={"md"}
                  variant="filled"
                  className="bg-gradient-to-br from-[#4d88c4] to-[#589bd6] px-3 py-3 hover:bg-gradient-to-r hover:from-[#589bd6] hover:to-[#4d88c4]"
                  onClick={insertVariable}
                >
                  <TbPlus /> <span className="ml-2">Add to Text</span>
                </Button>
              </div>
            </div>

            <div className="flex flex-row flex-wrap items-center">
              <div className="basis-full md:basis-1/3">Message Text</div>
              <div className="basis-full md:basis-1/2">
                <Textarea
                  size="md"
                  ref={templateRef}
                  placeholder="Message Text"
                  minRows={4}
                  maxRows={4}
                  spellCheck={false}
                  value={form.values.sms}
                  onChange={(event: any) => {
                    console.log(event);
                    form.setFieldValue("sms", event.currentTarget.value);
                    onClickSetMessage(event.currentTarget.value);
                  }}
                />
                <span className="flex justify-end p-1 font-mono  text-sm text-gray-500">
                  {isunicode ? "unicode" : "english"} {smslen} SMS
                </span>
              </div>
            </div>

            <div className="flex flex-row flex-wrap items-center">
              <div className="basis-full md:basis-1/3">Schedule Message</div>
              <div className="basis-full md:basis-1/2">
                <Radio.Group
                  {...form.getInputProps("isScheduled")}
                  value={form.values.isScheduled}
                >
                  <Radio value="yes" label="Yes" />
                  <Radio value="no" label="No" />
                </Radio.Group>
              </div>
            </div>

            {form.values.isScheduled === "yes" && (
              <>
                <div className="flex flex-row flex-wrap items-center">
                  <div className="basis-full md:basis-1/3">Schedule Date</div>
                  <div className="basis-full md:basis-1/2">
                    <TextInput
                      placeholder="Message Text"
                      size="md"
                      type={
                        form.values.isSplit === "yes"
                          ? "date"
                          : "datetime-local"
                      }
                      {...form.getInputProps("scheduleAt")}
                    />
                  </div>
                </div>
                {/* <div className="flex flex-row flex-wrap items-center">
                  <div className="basis-full md:basis-1/3">Split File</div>
                  <div className="basis-full md:basis-1/2">
                    <Radio.Group
                      {...form.getInputProps("isSplit")}
                      value={form.values.isSplit}
                    >
                      <Radio value="yes" label="Yes" />
                      <Radio value="no" label="No" />
                    </Radio.Group>
                  </div>
                </div> */}

                {/* {form.values.isSplit ===
                  "yes"(
                    <>
                      {form.values.splitdetails.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="flex flex-row flex-wrap items-center"
                          >
                            <div className="basis-full md:basis-1/3"></div>
                            <div className="flex basis-full space-x-3 md:basis-1/2">
                              <NumberInput
                                className="flex-1"
                                placeholder="From"
                                {...form.getInputProps(
                                  `splitdetails.${index}.form`
                                )}
                              />
                              <NumberInput
                                className="flex-1"
                                placeholder="To"
                                {...form.getInputProps(
                                  `splitdetails.${index}.to`
                                )}
                              />
                              <TextInput
                                className="flex-1"
                                type={"time"}
                                {...form.getInputProps(
                                  `splitdetails.${index}.time`
                                )}
                              />
                            </div>
                            {index <= 0 ? (
                              <div className="ml-3 basis-full md:basis-1/12">
                                <Button
                                  radius={"md"}
                                  variant="filled"
                                  className="bg-gradient-to-br from-[#4d88c4] to-[#589bd6] px-3 py-3 hover:bg-gradient-to-r hover:from-[#589bd6] hover:to-[#4d88c4]"
                                  onClick={() =>
                                    form.insertListItem("splitdetails", {
                                      form: "",
                                      to: "",
                                      time: "",
                                    })
                                  }
                                >
                                  <TbPlus />{" "}
                                  <span className="ml-2">Add Row</span>
                                </Button>
                              </div>
                            ) : (
                              <div className="ml-3 basis-full md:basis-1/12">
                                <ActionIcon
                                  size={"md"}
                                  variant="outline"
                                  color={"red"}
                                  onClick={() =>
                                    form.removeListItem("splitdetails", index)
                                  }
                                >
                                  <RiDeleteBin2Line />
                                </ActionIcon>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </>
                  )} */}
              </>
            )}

            <div>
              <Space h={"md"} />
              <div className="flex flex-row flex-wrap items-center">
                <div className="basis-full md:basis-1/3"></div>
                <div className="basis-full space-x-3 md:basis-1/2 ">
                  <Button
                    size="md"
                    variant="filled"
                    className="bg-gradient-to-br from-[#4d88c4] to-[#589bd6] px-7 py-3 hover:bg-gradient-to-r hover:from-[#589bd6] hover:to-[#4d88c4]"
                    onClick={BlastSms}
                  >
                    Submit
                  </Button>
                  <Button size="md" variant="outline" color={"#4d88c4"}>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default UploadCampaign;

//Message submitted to platform. Total Number Uploaded :- 5 .Duplicate/Incorrect Number Count :- 4 .Number submitted to platform:- 1
