import {
  Box,
  Button,
  NumberInput,
  Radio,
  Select,
  Space,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { useEffect, useState } from "react";
import { FaSave, FaSearch } from "react-icons/fa";
import { useMutation, useQuery } from "react-query";
import Wrapper from "../../components/Wrapper";
import { defaultUseQueryOption } from "../../helper";
import { ICreditInput, creditSchema } from "../../schema";
import { AddBalanceApi, CreditSearchApi, UserListApi } from "../../services";

const CreditManagment = () => {
  const [creditHistory, setCreditHistory] = useState({
    myCredit: "",
    myVirtual: "",
    clientCredit: "",
  });

  const { data: users } = useQuery<any, Error>(
    "users",
    UserListApi,
    defaultUseQueryOption()
  );

  const { mutate: creditSearch } = useMutation(CreditSearchApi, {
    onSuccess: (data) => {
      console.log("search data: ", data);

      setCreditHistory((prev) => ({
        ...data?.data,
      }));
    },
  });

  const { mutate: addCredit } = useMutation(AddBalanceApi, {
    onSuccess: () => {
      form.reset();
      showNotification({
        autoClose: 5000,
        title: "Credit Managment",
        message: "Credit Added Successfully",
        color: "green"
      })
    },
    onError: () => {
      showNotification({
        autoClose: 5000,
        title: "Credit Managment",
        message: "Something went wrong, please try again",
        color: "red"
      })
    }
  });


  const form = useForm<ICreditInput>({
    validate: zodResolver(creditSchema),
    initialValues: {
      clientid: "",
      credit: "",
      mode: "credit",
      description: "",
    },
  });

  const onCreditManagment = async (values: ICreditInput) => {
    //WIP
    if (form.values.mode === "debit") return alert("Work in progress");

    addCredit({ ...values })
  };

  useEffect(() => {
    if (form.values.clientid !== "")
      creditSearch({ userid: form.values.clientid });
  }, [creditSearch, form.values.clientid, form.values.mode]);

  return (
    <Wrapper
      header="Credit Managment"
      title="Dashboard,Users, Credit Managment"
    >
      <Box p={20} className="rounded-lg bg-white shadow-2xl">
        <Box
          component="form"
          onSubmit={form.onSubmit(onCreditManagment)}
          className="rounded-lg bg-[#e8eff7] "
        >
          <div className=" flex flex-col space-y-5 p-5">
            <div className="flex flex-row flex-wrap items-center">
              <label className="basis-full md:basis-1/3">Mode of Credit</label>
              <div className="basis-full md:basis-1/2">
                <Radio.Group
                  name="mode"
                  defaultValue={form.values.mode}
                  {...form.getInputProps("mode")}
                >
                  <Radio value="credit" label="Add Credit" />
                  <Radio value="debit" label="Deduct Credit" />
                </Radio.Group>
              </div>
            </div>

            <div className="flex flex-row flex-wrap items-center">
              <div className="basis-full md:basis-1/3">Select Username</div>
              <div className="basis-full md:basis-1/2">
                <div className="flex space-x-6">
                  <Select
                    {...form.getInputProps("clientid")}
                    className={"flex-1"}
                    size="md"
                    placeholder="Pick one"
                    searchable
                    icon={<FaSearch />}
                    defaultValue={form.values.clientid}
                    data={
                      users?.data?.map((user: any) => {
                        return {
                          value: user?.id,
                          label: user?.username,
                        };
                      }) || []
                    }
                  />
                </div>
              </div>
            </div>

            {form.values.mode === "credit" && (
              <div className="flex flex-row flex-wrap items-center">
                <div className="basis-full md:basis-1/3">
                  Your Available Credit
                </div>
                <div className="basis-full md:basis-1/2">
                  <TextInput
                    size="md"
                    disabled
                    readOnly
                    value={creditHistory?.myCredit}
                  />
                </div>
              </div>
            )}

            {Number(creditHistory?.myVirtual) > 0 &&
              form.values.mode === "credit" && (
                <div className="flex flex-row flex-wrap items-center">
                  <div className="basis-full md:basis-1/3">
                    Your Virtual Credit
                  </div>
                  <div className="basis-full md:basis-1/2">
                    <TextInput
                      size="md"
                      disabled
                      readOnly
                      value={creditHistory?.myVirtual}
                    />
                  </div>
                </div>
              )}

            <div className="flex flex-row flex-wrap items-center">
              <div className="basis-full md:basis-1/3">
                User Available Credit
              </div>
              <div className="basis-full md:basis-1/2">
                <TextInput
                  size="md"
                  disabled
                  readOnly
                  value={creditHistory?.clientCredit}
                />
              </div>
            </div>

            <div className="flex flex-row flex-wrap items-center">
              <div className="basis-full md:basis-1/3">
                {form.values.mode === "credit" ? "Add Credit" : "Deduct Credit"}
              </div>
              <div className="basis-full md:basis-1/2">
                <NumberInput
                  placeholder="Add Credits"
                  size="md"
                  {...form.getInputProps("credit")}
                />
              </div>
            </div>

            {form.values.mode === "credit" && (
              <>
                <div className="flex flex-row flex-wrap items-center">
                  <div className="basis-full md:basis-1/3">Amount</div>
                  <div className="basis-full md:basis-1/2">
                    <NumberInput
                      placeholder="Amount"
                      size="md"
                      {...form.getInputProps("amount")}
                    />
                  </div>
                </div>

                <div className="flex flex-row flex-wrap items-center">
                  <div className="basis-full md:basis-1/3">GST in %</div>
                  <div className="basis-full md:basis-1/2">
                    <NumberInput
                      placeholder="eg: 18%"
                      size="md"
                      {...form.getInputProps("gst")}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="flex flex-row flex-wrap items-center">
              <label className="basis-full md:basis-1/3">Description</label>
              <div className="basis-full md:basis-1/2">
                <Textarea {...form.getInputProps("description")} />
              </div>
            </div>

            <div>
              <Space h={"md"} />
              <div className="flex flex-row flex-wrap items-center">
                <div className="basis-full md:basis-1/3"></div>
                <div className="basis-full space-x-3 md:basis-1/2 ">
                  <Button
                    size="md"
                    variant="filled"
                    className="bg-gradient-to-br from-[#4d88c4] to-[#589bd6] px-7 py-3 hover:bg-gradient-to-r hover:from-[#589bd6] hover:to-[#4d88c4]"
                    type="submit"
                  >
                    <FaSave />{" "}
                    <span className="ml-2">
                      {form.values.mode === "credit"
                        ? "Add Credit"
                        : "Deduct Credit"}
                    </span>
                  </Button>
                  <Button size="md" variant="outline" color={"#4d88c4"}>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default CreditManagment;

//Message submitted to platform. Total Number Uploaded :- 5 .Duplicate/Incorrect Number Count :- 4 .Number submitted to platform:- 1
