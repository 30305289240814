import { Box, Button, Space, Tabs, TextInput } from "@mantine/core";
import { RiKey2Fill, RiLockPasswordFill, RiUser3Fill } from "react-icons/ri";
import { useQuery } from "react-query";
import SettingUserProfile from "../../components/SettingsUserProfile";
import UserApiKey from "../../components/UserApiKey";
import Wrapper from "../../components/Wrapper";
import { defaultUseQueryOption } from "../../helper";
import useAuth from "../../hooks/useAuth";
import { UserByIdApi } from "../../services";

const Settings = () => {
  const { data, isLoading: loaduser } = useQuery<any, Error>(
    "settinguser",
    () => UserByIdApi("", "clt"),
    defaultUseQueryOption()
  );
  //@ts-ignore
  const { user } = useAuth();

  console.log("user ::", data?.data?.misc?.apikey);

  return (
    <Wrapper header="User Details" title="Dashboard,Users, User Details">
      {loaduser ? (
        <div>Loading...</div>
      ) : (
        <Box p={20} className="rounded-lg bg-white shadow-2xl">
          <Box className="rounded-lg bg-[#e8eff7]">
            <Tabs defaultValue="user">
              <Tabs.List>
                <Tabs.Tab value="user" icon={<RiUser3Fill size={14} />}>
                  Details
                </Tabs.Tab>
                <Tabs.Tab
                  value="password"
                  icon={<RiLockPasswordFill size={14} />}
                >
                  Change Password
                </Tabs.Tab>
                {user?.role[0] === "client" &&
                  user?.misc?.usertype?.includes("api") && (
                    <Tabs.Tab value="api" icon={<RiKey2Fill size={14} />}>
                      Api Key
                    </Tabs.Tab>
                  )}
              </Tabs.List>
              <Tabs.Panel value="user" pt="xs">
                <SettingUserProfile user={data?.data} />
              </Tabs.Panel>
              <Tabs.Panel value="password" pt="xs">
                <div className=" flex flex-col space-y-5 p-5">
                  <div className="flex flex-row flex-wrap items-center">
                    <div className="basis-full md:basis-1/3">
                      Current Password
                    </div>
                    <div className="basis-full md:basis-1/2">
                      <TextInput placeholder="Current Password" size="md" />
                    </div>
                  </div>
                  <div className="flex flex-row flex-wrap items-center">
                    <div className="basis-full md:basis-1/3">New Password</div>
                    <div className="basis-full md:basis-1/2">
                      <TextInput placeholder="New Password" size="md" />
                    </div>
                  </div>
                  <div>
                    <Space h={"md"} />
                    <div className="flex flex-row flex-wrap items-center">
                      <div className="basis-full md:basis-1/3"></div>
                      <div className="basis-full space-x-3 md:basis-1/2 ">
                        <Button
                          size="md"
                          variant="filled"
                          className="bg-gradient-to-br from-[#4d88c4] to-[#589bd6] px-7 py-3 hover:bg-gradient-to-r hover:from-[#589bd6] hover:to-[#4d88c4]"
                          type="submit"
                          //   loading={isLoading}
                        >
                          <span className="ml-2">Update Password</span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Tabs.Panel>
              <Tabs.Panel value="api" pt="xs">
                {user?.role[0] === "client" &&
                  user?.misc?.usertype?.includes("api") && (
                    <UserApiKey apikey={data?.data?.misc?.apikey} />
                  )}
              </Tabs.Panel>
            </Tabs>
          </Box>
        </Box>
      )}
    </Wrapper>
  );
};

export default Settings;
