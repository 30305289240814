import { useWindowScroll } from "@mantine/hooks";
import { PropsWithChildren, useState } from "react";
import { FaAlignLeft } from "react-icons/fa";
import useAuth from "../hooks/useAuth";
import { LogoutApi } from "../services";
import SideMenu from "./SideMenu";

interface IWrapperProps {
  title: string;
  isdashboard?: boolean;
  header?: string;
}

const Wrapper = ({
  children,
  title,
  isdashboard,
  header,
}: PropsWithChildren<IWrapperProps>) => {
  //@ts-ignore
  const { setUser } = useAuth();
  const [scroll] = useWindowScroll();
  const Logout = async () => {
    await LogoutApi().then(() => {
      localStorage.removeItem("ux");
      setUser({ isauth: false });
    });
  };

  const [open, setOpen] = useState(true);

  return (
    <div
      className={`flex flex-[1_0_auto] flex-col bg-white  ${
        open ? "md:py-4 md:pr-4" : "md:p-4"
      }`}
    >
      <SideMenu open={open} setOpen={setOpen} />
      <div
        id="app_wrapper"
        className={`${
          open ? "ml-0" : ""
        } flex flex-auto flex-col transition-all ${
          open ? "md:ml-64" : "md:ml-0"
        }`}
      >
        <div className="relative flex h-full flex-col rounded-3xl bg-blue-50 pt-14">
          <div
            id="headder"
            className={`fixed z-10 flex items-center justify-between py-4 px-4  ${
              scroll.y > 10
                ? "top-0 right-0 left-0 rounded-none md:left-64"
                : `left-0 right-0 top-0 rounded-xl ${
                    open ? "md:left-72" : "md:left-10"
                  } md:top-10 md:right-10`
            }  bg-white p-2 shadow-sm transition-all`}
          >
            <button onClick={() => setOpen(!open)}>
              <FaAlignLeft size={24} color="#4d88c4" />
            </button>
            <button onClick={Logout}>Logout</button>
          </div>
          <main className="p-4 ">
            <div id="main " className="p-1 md:p-6">
              {!isdashboard && (
                <div className="mt-4 mb-8 flex flex-wrap items-center justify-between">
                  <h2 className="text-2xl font-bold">{header}</h2>
                  <div className="breadcrum flex">
                    {title.split(",").map((a, i) => {
                      return (
                        <h2 key={i} className="text-md font-semibold">
                          {a}
                        </h2>
                      );
                    })}
                  </div>
                </div>
              )}
              {children}
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default Wrapper;
