import { Box, Button, Radio, Space, TextInput } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { FaSave } from "react-icons/fa";
import { useMutation } from "react-query";
import Wrapper from "../../components/Wrapper";
import { IEntityInput, entitySchema } from "../../schema";
import { CreateEntityApi } from "../../services";

const AddEntity = () => {
  const form = useForm<IEntityInput>({
    validate: zodResolver(entitySchema),
    initialValues: {
      entity: "",
      header: "",
      sender: "",
      type: "others",
    },
  });

  const { mutate, isLoading } = useMutation(CreateEntityApi, {
    onSuccess: (data) => {
      form.reset();
      showNotification({
        autoClose:5000,
        title:"Entity, Sender Id",
        message:"Sender ID Add successfully done",
        color:"green"
      })
    },
    onError:()=>{
      showNotification({
        autoClose: 5000,
        title: "Entity, Sender Id",
        message: "Something went wrong, please try again",
        color: "red"
      })
    }
  });

  const onAddEntity = (values: IEntityInput) => {
    console.log(values);
    mutate(values);
  };

  return (
    <Wrapper header="Add Sender ID" title="DLT Managment,Entities, Add Entity">
      <Box p={20} className="rounded-lg bg-white shadow-2xl">
        <Box
          component="form"
          onSubmit={form.onSubmit(onAddEntity)}
          className="rounded-lg bg-[#e8eff7] "
        >
          <div className=" flex flex-col space-y-5 p-5">
            <div className="flex flex-row flex-wrap items-center">
              <label className="basis-full md:basis-1/3">
                Content Template Type
              </label>
              <div className="basis-full md:basis-1/2">
                <Radio.Group name="contenttype" {...form.getInputProps("type")}>
                  <Radio value="promotional" label="Promotional" />
                  <Radio
                    value="others"
                    label="Others (Transactional / Service Implicit / Service Explicit)"
                  />
                </Radio.Group>
              </div>
            </div>

            <div className="flex flex-row flex-wrap items-center">
              <label className="basis-full md:basis-1/3">Entity Id</label>
              <div className="basis-full md:basis-1/2">
                <TextInput
                  placeholder="Entity Id"
                  size="md"
                  {...form.getInputProps("entity")}
                />
              </div>
            </div>

            <div className="flex flex-row flex-wrap items-center">
              <label className="basis-full md:basis-1/3">Header Id</label>
              <div className="basis-full md:basis-1/2">
                <TextInput
                  placeholder="Header Id"
                  size="md"
                  {...form.getInputProps("header")}
                />
              </div>
            </div>

            <div className="flex flex-row flex-wrap items-center">
              <label className="basis-full md:basis-1/3">Sender Id</label>
              <div className="basis-full md:basis-1/2">
                <TextInput
                  placeholder="Sender Id"
                  size="md"
                  {...form.getInputProps("sender")}
                />
              </div>
            </div>

            <div>
              <Space h={"md"} />
              <div className="flex flex-row flex-wrap items-center">
                <label className="basis-full md:basis-1/3"></label>
                <div className="basis-full space-x-3 md:basis-1/2 ">
                  <Button
                    size="md"
                    variant="filled"
                    className="bg-gradient-to-br from-[#4d88c4] to-[#589bd6] px-7 py-3 hover:bg-gradient-to-r hover:from-[#589bd6] hover:to-[#4d88c4]"
                    type="submit"
                    loading={isLoading}
                  >
                    <FaSave /> <span className="ml-2">Submit</span>
                  </Button>
                  <Button size="md" variant="outline" color={"#4d88c4"}>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default AddEntity;

//Message submitted to platform. Total Number Uploaded :- 5 .Duplicate/Incorrect Number Count :- 4 .Number submitted to platform:- 1
